import { AgmCoreModule, MapsAPILoader, NoOpMapsAPILoader } from '@agm/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconRegistry } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ScrollbarModule } from './core/scrollbar/scrollbar.module';
import { createTranslateLoader } from './core/services/translation/translation.loader';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';



registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ScrollbarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot(),
    // Waka Modules
    RoutingModule,
    CoreModule,
    SharedModule,
    FeaturesModule,
  ],
  providers: [
    MatIconRegistry,
    // AGM Maps: Include this to avoid "You have included the
    // Google Maps API multiple times on this page" error
    { provide: MapsAPILoader, useClass: NoOpMapsAPILoader },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
