
/**
 * WK-TRANSLATION SYSTEM EXPLANATION:
 * This app uses ngx-translate for its translations: https://github.com/ngx-translate/core
 * - To export .po to .json, this app uses po2json: https://github.com/mikeedwards/po2json
 * - Due to an issue (https://github.com/mikeedwards/po2json/issues/67), line 52 of po2json/lib/parse.js
 *   has been modified.
 *
 * The default language for the app is English; the english words/sentences are used as base tags for all
 * the other translations.
 *
 * To translate the application, follow the next steps:
 * 1 - Apply the translate pipe (| translate) in templates, or the async translateService.translator.get(textToTranslate)
 *     or the sync translateService.translator.instant(textToTranslate) method in components, to all the texts that you
 *     want to translate.
 * 2 - Run 'npm run extract-pot' in the console, this will generate the .pot template (wk-office.pot) in
 *     '/src/assets/i18n/pot'.
 * 3 - Send the .pot to the translators, they will send you back the .po file with the translation (es.po, en.po...)
 * 4 - Add the .po files to the '/src/assets/i18n/po' folder.
 * 5 - Run 'npm run po2json:[lang]' (where [lang] is the language you want to export from .po to .json. The generated
 *     json files will go to '/src/assets/i18n/json' from where the app will read them.
 *     - The default language for translations is 'es', so if you want to translate to spanish,
 *       you can just run 'npm run po2json'.
 *     - You need to have the .po file from the language in order to export it to json ;)
 *     - Right now there are just the following languages available: 'es', 'en', 'de', 'it', 'fr', ru', 'zh', 'ja'.
 *       To include new ones, just add them in the package.json scripts, following the previous samples.
 *
 * Defaul Locale/Language:
 * - The default locale is taken from the ConfigService default locale.
 * - If the user changes the language, a wkUserDefaultLocale is created in localStorage (_userDefaultLocale
 *   in ConfigService). This wkUserDefaultLocale is used from then on, ignoring the app's default locale.
 **/

import { ConfigService } from './../config/config.service';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {

  constructor(
    private translateService: TranslateService,
    private configService: ConfigService,
  ) {
    this.configService
            .localeDefault$
            .subscribe(localeDefault => {
              this.translateService.setDefaultLang(localeDefault);
            });
  }

  get translator() {
    return this.translateService;
  }

  changeLang(lang) {
    this.translateService.use(lang);
    this.configService.setUserDefaultLocale(lang);
  }
}
