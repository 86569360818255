import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of as observableOf, Subscription } from 'rxjs';
import { filter, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { ConfigService } from './../../core/services/config/config.service';
import { MasterDetailService } from './../../core/services/master-detail/master-detail.service';
import { CanDeactivateDialogComponent } from './can-deactivate-dialog/can-deactivate-dialog.component';


@Component({
  selector: 'wk-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  master: string;
  detail;
  detailWithTable;
  tabs: IDetailTabConfig[];
  relations: IRelations;
  model: any;
  locales: ILocale[];
  formChanged: boolean;
  parent: string;
  errors = {};
  tabsFormGroup: FormGroup;
  requiredTabs = {};
  options = [];
  section: string;
  isStaticSection: boolean;
  resolvesSubscription: Subscription;
  customHandler: string;
  customHandlerConfig: Object;

  private _afterSave: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly afterSave$: Observable<any> = this._afterSave
                                                .asObservable()
                                                .pipe(
                                                  publishReplay(1),
                                                  refCount(),
                                                  filter(detail => detail != null),
                                                );

  canDeactivate(): Observable<boolean> {
    return this.formChanged ?
                  this.dialog
                        .open(CanDeactivateDialogComponent)
                        .afterClosed()
                        .pipe(
                          switchMap(save => {
                            if (save === true) {
                              return this.save(this.tabs, this.tabsFormGroup)
                                          .pipe(map((newItem) => true));
                            } else if (save === false) {
                              return observableOf(true);
                            } else {
                              return observableOf(false);
                            }
                          })
                        ) :
                  observableOf(true);
  }

  constructor(
    private configService: ConfigService,
    private masterDetailService: MasterDetailService,
    public dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<DetailComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogConfig,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit() {
    this.tabsFormGroup = new FormGroup({});
    // This component can take its config and model in two ways:
    // 1 - From Route resolve:
    //   * When it is a type:static section, and it has been loaded clicking in the menu or
    //   * When the detail is loaded from a custom component, not a master component
    // 2 - From MAT_DIALOG_DATA:
    //   * When it is loaded inside a MatDialog, it has been loaded clicking
    //     on the edit pencil icon on a master table).
    if (this.dialogConfig) {
      this.master = this.dialogConfig.master;
      this.detail = this.dialogConfig.detail;
      this.detailWithTable = this.detail || this.dialogConfig.table ? {...this.detail, table: this.dialogConfig.table} : null;
      this.tabs = this.dialogConfig.config.detail;
      this.section = this.dialogConfig.config.name;
      this.relations = this.dialogConfig.relations;
      this.model = this.dialogConfig.model;
      this.locales = this.configService.locales;
      this.customHandlerConfig = this.dialogConfig;
    } else {
      this.resolvesSubscription = this.activatedRoute.data.subscribe(resolves => {
        this.master = this.activatedRoute.snapshot.params.master;
        this.detail = {_id: this.activatedRoute.snapshot.params.detail};
        this.detailWithTable = this.detail;
        this.tabs = resolves.config.detail;
        this.section = resolves.config.name;
        this.relations = resolves.relations;
        this.model = resolves.data;
        this.locales = this.configService.locales;
        this.isStaticSection = this.activatedRoute.snapshot.queryParams &&
                               this.activatedRoute.snapshot.queryParams.isStatic === 'true';
        this.customHandlerConfig = resolves;
      })
    }

    this.tabs.forEach(tab => {
      tab.props.forEach(input => {
        if (input.type === 'option') { this.options.push(input.key) };
        if (input.validations && input.validations.required) {
          this.requiredTabs[tab.name] = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.resolvesSubscription) {
      this.resolvesSubscription.unsubscribe();
    }
  }

  save(tabs, tabsFormGroup): Observable<any> {
    const saveOperation = this.detail && this.detail._id ? 'updateDetail' : 'createDetail';
    const baseModel = this.detail && this.detail._id ? {_id: this.detail._id} : {};
    const tabsModel = tabs.reduce((dataToSave, tab) => {
      const tabFormValue = tabsFormGroup.get(tab.name).getRawValue();
      const tabFormModel = this.processOptions(tabFormValue, tab);

      Object.keys(tabFormModel).forEach(key => {
        dataToSave[key] = tabFormModel[key];
      });
      return dataToSave;
    }, baseModel);


    return this.masterDetailService[saveOperation](this.master, tabsModel)
                .pipe(
                  map(newItem => {
                    // When this detail contains a input.type === master in its form (MasterComponent),
                    // the master needs a parent to be able to show its data (ie: Hotels for a Client (parent))
                    // this.detailWithTable is this parent, so we only update it if there is no parent with _id
                    // (_id is assigned on server save).
                    if (this.dialogConfig && (!this.detailWithTable || !this.detailWithTable._id)) {
                      this.detailWithTable = {...newItem, table: this.dialogConfig.table};
                    }
                    this.formChanged = false;
                    this._afterSave.next(newItem);
                    return newItem;
                  })
                );
  }

  saveAndOptionalCreateNew(tabs, tabsFormGroup, createNew?) {
    this.save(tabs, tabsFormGroup)
          .subscribe(newItem => {
            if (createNew) {
              this.tabsFormGroup = new FormGroup({});
              this.detail = '';
              this.model = '';
              this.formChanged = false;
            } else if (!this.detail || !this.detail._id) {
              this.detail = newItem;
            }
          });
  }

  goBack() {
    // If this DetailComponent has been loaded in a dialog
    // run candeactivate guard and close the dialog and go
    // back to the master table
    if (this.dialogRef) {
      this.canDeactivate()
          .subscribe(canDeactivate => {
            if (canDeactivate) {
                this.dialogRef.close();
            }
          });
    } else {
      // Else go back and the router will take care of
      // running the canactivate guard
      this.location.back();
    }
  }

  processOptions(tabValue, tab) {
    const tabValueCopy = {...tabValue};
    Object.keys(tabValueCopy).forEach((key, index) => {
      if (this.options.indexOf(key) !== -1) {
        if (tabValueCopy[key] && !Array.isArray(tabValueCopy[key])) {
          tabValueCopy[key] = [tabValueCopy[key]];
        }
        tab.props.forEach(input => {
          if (input.key === key && input.extras.multiple && tabValueCopy[key] != null) {
            tabValueCopy[key] = tabValueCopy[key].reduce((filtered, multiItem, i) => {
              if (multiItem) {
                const multiValue = this.relations[input.extras.table.path]
                                          .filter(relation => relation[input.extras.table.itemValue] === tabValueCopy[key][i])
                                          .map(value => value._id)[0];
                filtered.push(multiValue);
              }
              return filtered;
            }, []);
          }
        });
      }
    })

    return tabValueCopy
  }
}
