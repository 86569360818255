/**
 * // WK-MASTER-DETAIL SYSTEM EXPLANATION:
 *
 * - Table and detail page follow the master-detail pattern.
 *
 * - Router:
 *   - Defines the master and detail states (':master' y ':master/:detail').
 *   - Resolves the configuration & data needed for the particular state
 *     throught this service (MasterDetailService).
 *
 *   - The states (master & detail) access to its details by subscribing its
 *     ActivatedRoute's data and params.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { NotificationService } from './../notification/notification.service';
import { TranslationService } from './../translation/translation.service';
import { WkHttpService } from './../wk-http/wk-http.service';


@Injectable()
export class MasterDetailService {
  endPoint: string;

  private _masterData: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly masterData$: Observable<any> = this._masterData.asObservable().pipe(publishReplay(1), refCount());

  private _detailData: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly detailData$: Observable<any> = this._detailData.asObservable().pipe(publishReplay(1), refCount());

  constructor(
    private wkHttpService: WkHttpService,
    private notificationService: NotificationService,
    private translationService: TranslationService,
    private userService: UserService,
  ) {
    this.endPoint = 'tables';
  }

  getMaster(master, query?): Observable<any> {
    let params: any = '';
    if (query) {
       params = {query: JSON.stringify(query)};
    }
    return this.wkHttpService
                    .get(`${this.endPoint}/${master}`, {params})
                    .pipe(
                      map(response => {
                        this._masterData.next(response);
                        return response;
                      })
                    );
  }

  getDetail(master, detail): Observable<any> {
    const params = {query: JSON.stringify({_id: detail})};
    return this.wkHttpService
                    .get(`${this.endPoint}/${master}`, {params})
                    .pipe(
                      map(response => {
                        this._detailData.next(response);
                        return response;
                      })
                    );
  }

  getRelations(path): Observable<IRelations> {
    return this.wkHttpService
                    .get(`${this.endPoint}/${path}/relations`)
                    .pipe(
                      map(response => {
                        return response;
                      })
                    );
  }

  createDetail(master, detail): Observable<any> {
    return this.wkHttpService
                    .post(`${this.endPoint}/${master}`, detail)
                    .pipe(
                      map((response) => {
                        const message = this.translationService.translator.instant('New item CREATED at');
                        this.notificationService.notify(`${message} ${master}`, 'X');

                        return response;
                      })
                    );
  }

  updateDetail(master, detail): Observable<any> {
    return this.wkHttpService
                  .patch(`${this.endPoint}/${master}`, detail)
                  .pipe(
                    map((response) => {
                      const message = this.translationService.translator.instant('Item SAVED to');
                      this.notificationService.notify(`${message} ${master}`, 'X');

                      return response;
                    })
                  );
  }

  deleteDetail(master, detail): Observable<any> {
    const params = {query: JSON.stringify({_id: detail})};
    return this.wkHttpService
                    .delete(`${this.endPoint}/${master}`, {params})
                    .pipe(
                      map((response) => {
                        const message = this.translationService.translator.instant('Item DELETED from');
                        this.notificationService.notify(`${message} ${master}`, 'X');

                        return response;
                      })
                    );
  }
}


