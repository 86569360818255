
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { WkHttpService } from '../wk-http/wk-http.service';



@Injectable()
export class ConfigService {
  endPoint: string;
  private _locales: ILocale[];
  private _sections: ISectionConfig[];
  private _groups: IGroupConfig[];
  private _bbdd: string;

  private _localeDefault: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly localeDefault$: Observable<string> = this._localeDefault.asObservable().pipe(publishReplay(1), refCount());
  private _userDefaultLocale: string;

  constructor(
    private wkHttpService: WkHttpService,
  ) {
    this.endPoint = 'configuration';

    const config: IAppConfig = JSON.parse(localStorage.getItem('wkConfig'));
    if (config) {
      this._userDefaultLocale = JSON.parse(localStorage.getItem('wkUserDefaultLocale'));
      this._bbdd = JSON.parse(localStorage.getItem('wkBbdd'));
      this.setLocales(config.locales);
      this._sections = config.sections;
      this._groups = config.groups;
    }
  }

  get localeDefault() {
    return this._userDefaultLocale || this._locales.find(locale => locale.default).locale;
  }

  get locales () {
    return this._locales;
  }

  get sections () {
    return this._sections;
  }

  get groups () {
    return this._groups;
  }

  get bbdd() {
    return this._bbdd;
  }

  setLocales(locales) {
    const defaulLocale = this._userDefaultLocale || locales.find(locale => locale.default).locale;
    this._localeDefault.next(defaulLocale);
    this._locales = locales;
  }

  setConfig(config: IAppConfig) {
    this.setLocales(config.locales);
    this._sections = config.sections;
    this._groups = config.groups;

    localStorage.setItem('wkConfig', JSON.stringify(config));
  }

  setUserDefaultLocale(locale) {
    this._userDefaultLocale = locale;
    localStorage.setItem('wkUserDefaultLocale', JSON.stringify(locale));
  }

  setBbdd(bbdd: string) {
    this._bbdd = bbdd;
    localStorage.setItem('wkBbdd', JSON.stringify(bbdd));
  }

  getConfig(): Observable<IAppConfig> {
    return this.wkHttpService
                    .get(`${this.endPoint}`)
                    .pipe(
                      map((config) => {
                        this.setConfig(config);
                        return config;
                      })
                    );
  }

  getSectionConfig(master): ISectionConfig {
    return this.sections.find(section => section.path === master);
  }

  removeConfig() {
    localStorage.removeItem('wkConfig');
    localStorage.removeItem('wkUserDefaultLocale');
    localStorage.removeItem('wkBbdd');
  }
}

