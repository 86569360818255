import { Component, OnInit, Optional, Input, Host, SkipSelf, Self, ViewChild, ElementRef } from '@angular/core';
import { ControlContainer, FormArray, AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { take } from 'rxjs/operators';

import { MatDialog, MatExpansionPanel } from '@angular/material';
import {MatSnackBar} from '@angular/material';


import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { WkHttpService } from '../../../../core/services/wk-http/wk-http.service';

@Component({
  selector: 'wk-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {
  parentControlContainer: ControlContainer;
  controlContainer: ControlContainer;
  fileFormArray: FormArray;
  fileFormGroup: FormGroup;
  files: FormControl;
  errorMessage: string;
  showDetailData = false;

  @ViewChild('uploadBar')
  uploadBar: ElementRef;

  @Input()
  controls: any[] = [
    {name: 'name', type: 'text', editable: false},
    {name: 'type', type: 'text', editable: false},
    {name: 'size', type: 'text', editable: false},
    {name: 'path', type: 'text', editable: false},
    {name: 'url', type: 'text', editable: false},
    {name: 'title', type: 'text', editable: true},
    {name: 'link', type: 'text', editable: true},
    {name: 'target', type: 'option', editable: true},
    {name: 'order', type: 'number', editable: true},
    {name: 'updated_time', type: 'text', editable: false},
  ];

  targets: any[] = [
    {name: 'Self', value: 'self'},
    {name: 'Blank', value: '_blank'},
  ];

  constructor(
    // Get a reference to the formArrayName. It's not working as a
    // directive in this host element :( (formArrayName extends ControlContainer).
    @Optional() @Host() @SkipSelf() parentControlContainer: ControlContainer,
    @Optional() @Host() controlContainer: ControlContainer,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.parentControlContainer = parentControlContainer;
    this.controlContainer = controlContainer;
  }

  ngOnInit() {

    // Hacky trick to assign the proper formGroup inside the formArray to this component
    this.fileFormArray = this.parentControlContainer.control as FormArray;
    this.fileFormGroup = this.controlContainer.control as FormGroup;

    this.fileFormGroup.valueChanges.pipe(take(1)).subscribe(changes => {
      this.fileFormGroup.get('update_time').setValue(true);
    });
  }

  removeFile() {
    this.fileFormArray
            .controls
            .forEach((file: FormGroup, index) => {
              if (file.controls._id.value === this.fileFormGroup.controls._id.value) {
                this.fileFormArray.removeAt(index);
              }
            });
  }

  expandPanel(matExpansionPanel: MatExpansionPanel, event: Event) {
    event.stopPropagation();
    this.showDetailData = !this.showDetailData;
    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.close();
    }
  }

  previewImage(imageUrl) {
    this.dialog.open(ImagePreviewComponent, { data: imageUrl, panelClass: 'image-preview-dialog' });
  }

  private _isExpansionIndicator(target): boolean {
    const expansionIndicatorClass = 'prevent-expand';
    return (target.classList && !target.classList.contains(expansionIndicatorClass) );
  }

  showSnackbar() {
    this.snackBar.open('Copied!', null, {
      duration: 500,
    });
  }

}
