import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentService } from '../../../core/services/moment/moment.service';
import { FormArrayValidator } from '../../../core/validators/form-array.validator';



@Injectable()
export class FormsService {
  angularDefaultValidators = ['min', 'max', 'required', 'requiredtrue', 'email', 'minlength', 'maxlength', 'pattern', 'nullValidator'];
  file: IFile = {
    name: '',
    type: '',
    size: '',
    path: '',
    url: '',
    title: '',
    link: '',
    target: '',
    basePath: '',
    nodeId: '',
    prop: '',
    section: '',
    _id: '',
    update_time: '',
    order: null,
  }

  location: ILocation = {
    address: null,
    lat: null,
    lng: null,
    formattedAddres: null,
    country: null,
    area: null,
    region: null,
    locality: null,
    postal_code: null,
  }

  seo: ISEO = {
    title: null,
    description: null,
  }

  constructor(
    private formBuilder: FormBuilder,
    private momentService: MomentService,
  ) { }

  toFormGroup(formConfig: IDetailFormConfig[], locales: ILocale[], model?, relations?: IRelations): FormGroup {
    const formGroup = {};
    const localeDefault = locales.find(locale => locale.default);

    formConfig.forEach(input => {
      if (input.type === 'action') {
        return;
      } else if (input.extras &&
                 input.extras.multiLang &&
                 (input.type === 'text' || input.type === 'textarea')) {
        const localeFormControls = {};

        locales.forEach(locale => {
          localeFormControls[locale.locale] = [{value: '', disabled: input.extras && input.extras.disabled}, []];
        });
        formGroup[input.key] = this.formBuilder.group(localeFormControls);
      } else if (input.type === 'option' &&
                 input.extras.display &&
                 input.extras.multiple) {
        formGroup[input.key] = new FormArray([]);
        relations[input.extras.table.path].forEach(() => formGroup[input.key].push(new FormControl()));
      } else if (input.type === 'files') {
        formGroup[input.key] = new FormArray([]);
        if (model && model[input.key]) {
          model[input.key].forEach(file => {
            if (input.key === file.prop) {
              formGroup[input.key].push(this.formBuilder.group(this.file));
            }
          });
        }
      } else if (input.type === 'location') {
        formGroup[input.key] = this.formBuilder.group(this.location);
      } else if (input.type === 'seo') {
        if (input.extras &&
          input.extras.multiLang) {
            const localeFormControls = {};
            locales.forEach(locale => {
              localeFormControls[locale.locale] = [{value: '', disabled: input.extras && input.extras.disabled}, []];
            });
            const localeSeoFormGroup = {
              title: this.formBuilder.group(localeFormControls),
              description: this.formBuilder.group(localeFormControls),
            }
            formGroup[input.key] = this.formBuilder.group(localeSeoFormGroup);
          } else {
            formGroup[input.key] = this.formBuilder.group(this.seo);
          }
      }else {
        if (input.type === 'date' && input.validations) {
          if (input.validations.min) { input.validations.min = this.momentService.moment(input.validations.min); }
          if (input.validations.max) { input.validations.max = this.momentService.moment(input.validations.max); }
        }
        formGroup[input.key] = [{value: null, disabled: input.extras && input.extras.disabled}, []];
      }

      const formControl = formGroup[input.key];
      const formControlValidations = formControl[1];

      if (input.validations) {
        const validators = [];

        Object.keys(input.validations)
                .forEach(validationKey => {
                  const validationValue = input.validations[validationKey];
                  if (this.angularDefaultValidators.indexOf(validationKey) !== -1) {
                    const validatorToPush = typeof validationValue === 'string' || typeof validationValue === 'number' ?
                                                  Validators[validationKey](validationValue) :
                                                  Validators[validationKey];
                    validators.push(validatorToPush);
                  } else {
                    // Handle custom validations
                    if (validationKey === 'decimals') {
                      validators.push(this.decimalsValidator(validationValue));
                    }
                  }
                });

        if (input.extras && input.extras.multiLang && input.type !== 'option') {
          locales.forEach(locale => {
            const requiredIndex = validators.indexOf(Validators.required);
            if (requiredIndex !== -1 && locale !== localeDefault) {
              const validatorsCopy = [...validators];
              const validatorsToPush = validatorsCopy.splice(requiredIndex, 1);
              formControl.controls[locale.locale].setValidators(validatorsCopy);
            } else {
              formControl.controls[locale.locale].setValidators(validators);
            }
          });
        } else if (input.type === 'option' &&
                   input.extras.display &&
                   input.extras.multiple) {
          // TODO: Implement other validations
          if (input.validations.required) {
            formControl.setValidators(FormArrayValidator.validate);
          }
        } else if (input.type === 'files') {
          if (input.validations.required) {
            formControl.setValidators(FormArrayValidator.validate);
          }
        }  else if (input.type === 'location') {
          if (input.validations.required) {
            formControl.get('lat').setValidators(Validators.required);
            formControl.get('lng').setValidators(Validators.required);
          }
        } else {
          validators.forEach(validator =>  formControlValidations.push(validator));
        }
      }
    });

    const form = this.formBuilder.group(formGroup);

    return model ? this.fillWithModel(form, formConfig, locales, model, relations) : form;
  }

  fillWithModel(form, formConfig, locales, model, relations?) {
    formConfig.forEach(input => {
      // TODO Diferenciar entre formControl, formArray y formGroup para evitar confusiones.
      const formControl = form.controls[input.key];
      let formControlModel;

      if (model[input.key] != null) {
        formControlModel = model[input.key];
      } else if (input.type === 'files' || input.type === 'options') {
        formControlModel = [];
      } else {
        formControlModel = null;
      }

      if (input.type === 'action') {
        return;
      } else if ((input.type === 'text' || input.type === 'textarea') &&
                  input.type !== 'option' &&
                  input.extras && input.extras.multiLang) {
        locales.forEach(locale => {
          formControl.controls[locale.locale].setValue(formControlModel && formControlModel[locale.locale]);
        });
      } else if (input.type === 'date' && formControlModel) {
        const momentDate =  this.momentService.moment(formControlModel);
        formControl.setValue(momentDate);
      } else if (input.type === 'option' && !input.extras.multiple) {
        if (Array.isArray(formControlModel) && formControlModel.length) {
          formControl.setValue(formControlModel[0]);
        } else {
          formControl.setValue(formControlModel);
        }
      } else if (input.type === 'option' &&
                 input.extras.display &&
                 input.extras.multiple) {
        relations[input.extras.table.path].forEach((relation, index) => {
          if (model[input.key].indexOf(relation[input.extras.table.itemValue]) !== -1) {
            formControl.at(index).setValue(true);
          }
        });
      } else if (input.type === 'files') {
        let i = 0;
        if (formControlModel) {
          formControlModel.forEach(file => {
            if (input.key === file.prop) {
              formControl.at(i).setValue(file);
              i++;
            }
          });
        }
      } else if (input.type === 'location') {
        formControl.setValue(formControlModel || this.location);
      } else if (input.type === 'seo'){
        formControl.setValue(formControlModel || this.seo);
      } else {
        formControl.setValue(formControlModel);
      }
    });

    return form;
  }

  getErrors(form, errors = [], parent?) {
    if (form.controls) {
      const controlKeys = Object.keys(form.controls);

      controlKeys.forEach(controlKey => {
        const control = form.get(controlKey);
        if (control['controls']) {
          return this.getErrors(control, errors, controlKey);
        } else {
          this.angularDefaultValidators.forEach(validator => {
            if (control.hasError(validator) && control.touched) {
              const error = {
                // TODO: find a better way of checking if this control is multilanguage
                control: parent && controlKey.length === 2 ? `${parent}(${controlKey})` : controlKey,
                error: validator,
              };
              if (!errors.some(err => err.control === error.control && err.error === error.error)) {
                errors.push(error);
              }
            }
          });
        }
      });
    }
    return errors;
  }

  decimalsValidator(limitOfDecimals: number) {
    return (control: AbstractControl) => {
      const value = control.value;
      if (value % 1 !== 0) {
        const numberOfDecimals = value.toString().split('.')[1].length;
        if (numberOfDecimals > limitOfDecimals) {
          return { decimals: limitOfDecimals };
        }
      }
      return null;
    }
  }
}
