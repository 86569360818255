

import { Injectable } from '@angular/core';
import {fromEvent as observableFromEvent, of as observableOf, merge as observableMerge,  Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable()
export class OnlineService {

  get status$(): Observable<boolean> {
    return observableMerge(
                observableOf(navigator.onLine),
                observableFromEvent(window, 'online').pipe(map(() => true)),
                observableFromEvent(window, 'offline').pipe(map(() => false)),
              );
  }

}
