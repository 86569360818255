import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WkHttpService } from '../../core/services/wk-http/wk-http.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'wk-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: PermissionsComponent, multi: true }
  ]
})
export class PermissionsComponent implements OnInit, ControlValueAccessor {

  onChange: Function;
  onTouched: Function;
  isDisabled: boolean;
  form: FormGroup;
  value: string[];


  permissions: Array<any> = [];
  constructor(
    private wkHttp: WkHttpService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.loadPermissionsData();
  }

  loadPermissionsData(): void {
    this.wkHttp.get(`auth/user-permissions`, {}, environment.basePath).toPromise().then((data) => {
      this.permissions = data;
      const formData = this.permissions.reduce((result, p) => {
        result[p._id] = this.value && this.value.indexOf(p._id) > -1;
        return result;
      }, {});
      this.form = this.fb.group(formData);
      this.form.valueChanges.subscribe(changes => {
        const valuesToEmit = [];
        Object.keys(changes).forEach(rolId => {
          if (changes[rolId]) {
            valuesToEmit.push(rolId);
          }
        });
        this.value = valuesToEmit;
        this.onChange(this.value);
      });
    })
  }

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
