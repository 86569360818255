import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth-service/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  constructor(
    private injector: Injector,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: Build a map of urls that doesn't need authoritation.
    const urlFragments = request.url.split('/');
    if (urlFragments[urlFragments.length - 1] === 'login') {
      return next.handle(request);
    }

    this.authService = this.injector.get(AuthService);
    const authReq = request.clone({
      setHeaders: {
        Authorization: this.authService.getToken() || []
      }
    });

    return next.handle(authReq);
  }
}
