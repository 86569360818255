import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../../../environments/environment';
import { UserService } from './../user/user.service';




@Injectable()
export class WkHttpService {
  private _error: BehaviorSubject<any> = new BehaviorSubject({});
  readonly error$: Observable<any> = this._error.asObservable().pipe(publishReplay(1), refCount(),);

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) {}

  // Intercept the Http action & track its status (loading/not loading, error)
  get(url: string, options = {}, basePath?: string): Observable<any> {
    const endPoint = basePath ? `${basePath}/${url}` : `${environment.basePath}/${url}`;

    return this.httpClient.get(endPoint, options);
  }

  post(url: string, body: any, options = {}, basePath?: string): Observable<any> {
    const endPoint = basePath ? `${basePath}/${url}` : `${environment.basePath}/${url}`;

    return this.httpClient.post(endPoint, body, options);
  }

  put(url: string, body: any, options = {}, basePath?: string): Observable<any> {
    const endPoint = basePath ? `${basePath}/${url}` : `${environment.basePath}/${url}`;

    return this.httpClient.put(endPoint, body, options);
  }

  delete(url: string, options = {}, basePath?: string): Observable<any> {
    const endPoint = basePath ? `${basePath}/${url}` : `${environment.basePath}/${url}`;

    return this.httpClient.delete(endPoint, options);
  }

  patch(url: string, body: any, options = {}, basePath?: string): Observable<any> {
    const endPoint = basePath ? `${basePath}/${url}` : `${environment.basePath}/${url}`;

    return this.httpClient.patch(endPoint, body, options);
  }

  head(url: string, options?): Observable<any> {
    const endPoint = `${environment.basePath}/${url}`;

    return this.httpClient.head(endPoint, options);
  }

  options(url: string, options?): Observable<any> {
    const endPoint = `${environment.basePath}/${url}`;

    return this.httpClient.options(endPoint, options);
  }
}

export function customHttpFactory (httpClient: HttpClient, userService: UserService) {
    return new WkHttpService(httpClient, userService);
}

export let customHttpProvider = {
  provide: WkHttpService,
  useFactory: customHttpFactory,
  deps: [ HttpClient, UserService ]
};




