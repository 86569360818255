
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, publishReplay, refCount } from 'rxjs/operators';
import { WkHttpService } from '../../../core/services/wk-http/wk-http.service';
import { ConfigService } from '../../../core/services/config/config.service';



@Injectable()
export class TableService {
  private sourceTable: ITable;
  private sourceTableCopy: ITable;
  private searchFilterCriteria: string;
  private selectFiltersCriteria: Object;
  private sortCriteria: string;
  private oldSortCriteria: string;
  private orderDescendent = false;
  private locale: string;

  constructor(
    private wkHttpService: WkHttpService,
    private configService: ConfigService,
  ) {
    this.locale = this.configService.localeDefault;
  }

  // tslint:disable-next-line:member-ordering
  private _table: BehaviorSubject<ITable> = new BehaviorSubject(null);
  // tslint:disable-next-line:member-ordering
  readonly table$: Observable<ITable> = this._table.asObservable()
                                                    .pipe(
                                                      debounceTime(400),
                                                      publishReplay(1),
                                                      refCount(),
                                                      map(table => this._filterTable(table, this.searchFilterCriteria, this.selectFiltersCriteria, this.locale)),
                                                      map(table => this._sortTable(table, this.sortCriteria, this.orderDescendent, this.locale))
                                                    );

  setTable(config: ISectionConfig, data: any) {
    this.sourceTable = {columns: config.master, rows: data, relations: config.relations};
    this.sourceTableCopy = {...this.sourceTable};
    this.refreshTable();
  }

  refreshTable() {
    this._table.next(this.sourceTableCopy);
  }

  filter(searchFilterCriteria: string, selectFiltersCriteria: Object, locale: string) {
    this.searchFilterCriteria = searchFilterCriteria;
    this.selectFiltersCriteria = selectFiltersCriteria;
    this.locale = locale;
    this._table.next(this.sourceTableCopy);
  }

  sort(sortCriteria: string, locale: string) {
    this.sortCriteria = sortCriteria;
    if (this.sortCriteria !== this.oldSortCriteria) {
      this.oldSortCriteria = sortCriteria;
      this.orderDescendent = false;
    } else {
      this.orderDescendent = !this.orderDescendent;
    }
    this.locale = locale;
    this._table.next(this.sourceTableCopy);
  }

  private _filterTable(table: ITable, searchFilterCriteria: string, selectFiltersCriteria: Object, locale: string) {
    let filteredRows;
    const selectFilters = selectFiltersCriteria && Object.keys(selectFiltersCriteria) || [];

    if (searchFilterCriteria == null && !selectFilters.length) {
      return table;
    }

    if (selectFilters.length) {
      filteredRows = table.rows;
      selectFilters.forEach(filter => {
        filteredRows = filteredRows.filter(row => row[filter].indexOf(selectFiltersCriteria[filter]) !== -1);
      });
    }

    if (searchFilterCriteria != null) {
      const rowsToFilter = filteredRows || table.rows;

      filteredRows = rowsToFilter
                        .filter(row => {
                          let match = false;
                          const keys = Object.keys(row);
                          searchFilterCriteria = searchFilterCriteria.toString().toLowerCase();
                          const isMatch = data => {
                            if (data != null) {
                              return data.toString().toLowerCase().includes(searchFilterCriteria);
                            }
                            return false;
                          };
                          keys.forEach(key => {
                            let type;
                            for (let prop in table.columns){
                              if (table.columns[prop].key == key){
                                type = table.columns[prop].type;
                              }
                            }
                            if (row[key] == null) { return; }
                            if (type == 'option') {
                              row[key].forEach(option => {
                                if (isMatch(option[locale])) {
                                  match = true;
                                }
                              });
                            } else if (typeof row[key] === 'object') {
                              for (const prop in row[key]) {
                                if (isMatch(row[key][prop])) {
                                  match = true;
                                }
                              }
                            } else if (typeof row[key] === 'string') {
                              if (row[key].toLowerCase().includes(searchFilterCriteria)) {
                                match = true;
                              }
                            } else if (typeof row[key] === 'number') {
                              if (isMatch(row[key])) {
                                match = true;
                              }
                            }

                          });

                          return match;
                        });
    }

    const filteredTable = {...table, rows: filteredRows};
    return filteredTable;
  }

  private _sortTable(table: ITable, sortCriteria: string, orderDescendent: boolean, locale: string) {
    const type = table.columns.filter(column => column.key === sortCriteria)
                  .map(column => column.type)[0];
    if (sortCriteria == null) { return table; }

    let elementA;
    let elementB;

    const sortedRows = table.rows.sort((a, b) => {
      if (Array.isArray(a[sortCriteria])) {
        elementA = typeof a[sortCriteria][0] === 'object' ? a[sortCriteria][0][locale] : a[sortCriteria][0];
        elementB = typeof b[sortCriteria][0] === 'object' ? b[sortCriteria][0][locale] : b[sortCriteria][0];
      } else {
        if(type === 'boolean' || type === 'number') {
          elementA = a[sortCriteria];
          elementB = b[sortCriteria];
        } else {
          elementA = typeof a[sortCriteria] === 'object' ? a[sortCriteria][locale] : a[sortCriteria];
          elementB = typeof b[sortCriteria] === 'object' ? b[sortCriteria][locale] : b[sortCriteria];
        }
      }

      const order = orderDescendent ? 1 : -1;

      if (elementA < elementB) { return order; }
      if (elementA > elementB) { return -order; }
      return 0;
    });

    const sortedTable = {...table, rows: sortedRows};
    return sortedTable;
  }

  removeTable() {
    this._table.next(null);
  }

  exportData(table, ids): Observable<any> {
    return this.wkHttpService.post(`tables/${table}/export?locale=${this.locale}`, {_ids: ids});
  }

  getTable(): ITable {
    return this._table.getValue();
  }
}

