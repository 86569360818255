import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigService } from './../services/config/config.service';

@Injectable()
export class AdminResolverService implements Resolve<any> {
  constructor(
    private configService: ConfigService,
  ) {}

  resolve(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IAppConfig> {
    return this.configService.getConfig();
  }
}
