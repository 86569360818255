import { ClipboardService } from './service/clipboard.service';
import { Directive, ElementRef, HostListener, Input, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[wkClipboard]',
  providers: [ClipboardService],
})
export class ClipboardDirective {

  @Input('wkClipboard')
  url: string;

  @Output()
  wkClipboardSuccess: EventEmitter<void> = new EventEmitter();

  @HostBinding('style.color') color: string;

  @HostListener('click') copyToClipboard() {
    this.clipboardService
          .copy(this.url)
          .then(result => {
            this.color = 'green';
            this.wkClipboardSuccess.emit();
            setTimeout(() => {
              this.color = '';
            }, 1000);
          })
          .catch(error => {
            this.color = 'red';
            setTimeout(() => {
              this.color = '';
            }, 1000);
          });
  }

  constructor(
    private element: ElementRef,
    private clipboardService: ClipboardService,
  ) { }
}
