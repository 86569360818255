// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  googleApi: 'AIzaSyAuXYzJNROuJsCHn9m0JGwuYoQ8MjP_vZA',
  // basePath: `http://192.168.0.9:5003/admin`,
  basePath: `https://apiwk.wakasolutions.com/admin`,
  customBasePath: ``,
  wkqlPath: `https://apiwk.wakasolutions.com`,
  hash: ''
};
