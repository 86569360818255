import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { WkHttpService } from './../../core/services/wk-http/wk-http.service';
import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'wk-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectGroupComponent, multi: true }
  ]
})
export class SelectGroupComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  // Type this config
  @Input()
  config: any;
  @Input()
  relations: any;
  @Input()
  activeLocale: string;

  // ControlValueAccessor interface
  value: any;
  onTouch: Function;
  onChange: Function;

  // Map of relations (options) loaded
  relationsMap = {};
  lastSelectValueTracker = {};
  formGroup: FormGroup;
  parentControlConfig: IMasterColumnConfig;
  childControlsConfigs: IMasterColumnConfig[];
  formSubscription: Subscription;
  isDisabled: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private wkHttpService: WkHttpService,
  ) { }

  ngOnInit() {
    this.setUpForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setUpForm();
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  setUpForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    // Get all the options of selects without queries (non dependent) and build the relationsMap
    this.buildRelationsMap(this.config.controls);
    this.formGroup = this.builForm(this.config.controls, this.formBuilder);
    this.parentControlConfig = this.config.controls.find(control => control.extras.table.queries == null);
    this.childControlsConfigs = this.config.controls.filter(control => control.extras.table.queries != null);

    // Listen to form changes to enable/disable and load options for child selects
    this.formSubscription = this.formGroup
                                    .valueChanges
                                    .subscribe(changes => {
                                      const parentChanged = !this.areEqual(this.lastSelectValueTracker[this.parentControlConfig.key], this.formGroup.getRawValue()[this.parentControlConfig.key]);

                                      if (parentChanged) {
                                        // TODO: When parent changes its value and the this option-group
                                        // already had data, mark as invalid the required child selects (so the user
                                        // can see clear the fields that are wrong)

                                        // Clear the last value of the form
                                        this.lastSelectValueTracker = {};
                                        // Clear all childs values
                                        this.childControlsConfigs.forEach(childControlConfig => {
                                          const childControl = this.formGroup.get(childControlConfig.key);

                                          childControl.reset(null, {emitEvent: false});

                                          // Disable all selects that depend on other selects values
                                          if (childControlConfig.extras.table.queries) {
                                            childControl.disable({emitEvent: false});
                                          }
                                        });
                                      }

                                      let anyDependentSelectChanged = false;
                                      // Iterate over every select and call its options only if the dependent select changed
                                      this.childControlsConfigs
                                            .forEach(childControlConfig => {
                                              // Check if every dependent select has value and any has changed
                                              // before get/refresh its options
                                              const formValue = this.formGroup.getRawValue();
                                              const dependentSelectsKeys = childControlConfig.extras.table.queries.map(query => query.formKey);
                                              const dependentSelectsHaveValue = dependentSelectsKeys.every(dependentSelectKey => formValue[dependentSelectKey] != null);
                                              const dependentSelectsChanged = dependentSelectsKeys.some(dependentSelectKey => !this.areEqual(formValue[dependentSelectKey], this.lastSelectValueTracker[dependentSelectKey]));

                                              if (dependentSelectsChanged) {
                                                anyDependentSelectChanged = true;
                                              }

                                              if (dependentSelectsHaveValue && dependentSelectsChanged) {
                                                const childControl = this.formGroup.get(childControlConfig.key);
                                                childControl.reset(null, {emitEvent: false});

                                                this.getOptions(childControlConfig.extras.table.path, childControlConfig.extras.table.queries)
                                                      .subscribe(options => {
                                                        this.relationsMap[childControlConfig.extras.table.path] = options;
                                                        this.formGroup.get(childControlConfig.key).enable({emitEvent: false});
                                                      });
                                              }
                                            });

                                      // Update the last value of the form
                                      if (anyDependentSelectChanged) { this.lastSelectValueTracker = {...this.formGroup.getRawValue()}; }


                                      // If all required fields have value, emit this multi-select's value
                                      const allRequiredFieldsHaveValue = this.config
                                                                                .controls
                                                                                .filter(control =>  control.validations && control.validations.required)
                                                                                .every(control => this.formGroup.getRawValue()[control.key] != null);

                                      if (allRequiredFieldsHaveValue) {
                                        const formModel = this.formGroup.getRawValue();
                                        const valueToEmit = Object
                                                              .keys(formModel)
                                                              .reduce((result, modelKey) => {
                                                                return Array.isArray(formModel[modelKey]) ?
                                                                        result = {...result, [modelKey]: formModel[modelKey]} :
                                                                        result = {...result, [modelKey]: [formModel[modelKey]]};
                                                              }, {});

                                        this.onChange(valueToEmit);
                                      } else {
                                        this.onChange(null);
                                      }
                                    });
  }

  getOptions(table, queries?) {
    let queryToSend = {};

    if (queries) {
      queries.forEach(query => {
        const queryValue = this.formGroup.getRawValue()[query['formKey']] || this.value[query['formKey']];
        queryToSend = {
          ...queryToSend,
          [query['ddbbKey']]: queryValue,
        }
      });
    }

    const optionsToSend = { params: { query: JSON.stringify(queryToSend)}}

    return this.wkHttpService.get(`tables/${table}/relations/groups`, optionsToSend);
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value || null;
    if (this.value) {
      this.fillFormWithValue(this.config.controls, this.value);
    } else {
      if (this.config.controls) {
        const defaultState = this.config.controls.reduce((result, control) => {
          return result = {...result, [control.key]: null};
        }, {});
        this.formGroup.reset(defaultState, {emitEvent: false});
      }
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
    this.isDisabled ?
      this.formGroup.disable() :
      this.formGroup.enable();
  }

  buildRelationsMap(controls) {
    controls.forEach(control => {
      if (this.relations[control.extras.table.path]) {
        this.relationsMap[control.extras.table.path] = [...this.relations[control.extras.table.path]];
      }
    });
  }

  builForm(controls, formBuilder) {
    const formConfig = controls
                        .reduce((formConfigResult, controlConfig) => {
                          return {
                            ...formConfigResult,
                            [controlConfig.key]: [
                              {value: null, disabled: controlConfig.extras.table.queries && controlConfig.extras.table.queries.length},
                              controlConfig.validations && controlConfig.validations.required ? Validators.required : null,
                            ]
                          };
                        }, {});

    return formBuilder.group(formConfig);
  }

  fillFormWithValue(controls, value) {
    const controlsToGetOptions = controls.filter(control => control.extras.table.queries != null);
    const optionsObservables = controlsToGetOptions.map(control => this.getOptions(control.extras.table.path, control.extras.table.queries));

    forkJoin(optionsObservables)
      .subscribe(options => {
        controlsToGetOptions.forEach((control, index) => this.relationsMap[control.extras.table.path] = options[index]);
        controls.forEach(control => {
          const selectControl = this.formGroup.get(control.key);
          const selectValue = value[control.key] && value[control.key].length > 1 ?
                                value[control.key] :
                                value[control.key][0];

          this.lastSelectValueTracker[control.key] = selectValue;
          selectControl.setValue(selectValue, {emitEvent: false});
          if (!this.isDisabled) {
            selectControl.enable({emitEvent: false});
          }
        })
      });
  }

  areEqual(elem1, elem2) {
    if (Array.isArray(elem1) && Array.isArray(elem2)) {
      return elem1.length === elem2.length &&
             elem1.every(elem1Item => elem2.includes(elem1Item));
    } else {
      return elem1 === elem2;
    }
  }
}
