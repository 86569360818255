import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MasterDetailService } from './../services/master-detail/master-detail.service';

@Injectable()
export class MasterDetailRelationsResolverService implements Resolve<any> {
  constructor(
    private masterDetailService: MasterDetailService,
  ) {}

  resolve(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const master = activeRoute.params['master'];

    return this.masterDetailService.getRelations(master);
  }
}
