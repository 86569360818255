import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'wk-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: InputFileComponent, multi: true }
  ]
})
export class InputFileComponent implements OnInit, ControlValueAccessor {
  private value: any;
  imagesToRead = 0;
  id;

  errorMessage: string;
  files: IFile[] = [];
  file: IFile = {
    name: '',
    type: '',
    size: '',
    path: '',
    url: '',
    title: '',
    link: '',
    target: '',
    update_time: '',
  }

  onTouch: Function;
  onChange: Function;

  @Input()
  multiple: boolean;
  @Input()
  managerFiles: IFile[];

  @Input()
  display: string;

  constructor() {}

  ngOnInit() {
    this.id = Math.random();
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  onModelChange(files) {
    this.files = Array.from(files);
    if (this.files.length) {
      this.files.forEach((file, index) => {
        this.imagesToRead++;
        this.readImage(file, index);
      });
    };
  };

  writeValue(value) {
    this.value = value || '';
  }

  readImage(image, index) {
    const reader = new FileReader();
    reader.onerror = (error) => {
      this.errorMessage = 'There was and error loading the image: ${error}.<br> Please try again.';
    };
    reader.onload = () => {
      this.files[index].base64 = reader.result;
      this.imagesToRead--;
      if (!this.imagesToRead) {
        this.onChange(this.files);
      }
    };
    reader.readAsDataURL(image);
  }
}
