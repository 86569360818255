import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth/auth-service/auth.service';

@Component({
  selector: 'wk-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  @Input()
  input: any;
  @Input()
  parentForm: FormGroup;

  master: string;
  detail: string;

  @Output()
  change = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.master = params.master;
      this.detail = params.detail;
    });
  }

  changePassword(input) {
    const dialogRef = this.dialog.open(ChangePasswordComponent)

    dialogRef.afterClosed().subscribe(newPassword => {
      if (newPassword) {
        this.parentForm.get(this.input.key).setValue(newPassword);
      }
    });
  }

  resetPassword(input) {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      data: this.parentForm.get(this.input.extra.email_field).value
    })

    dialogRef.afterClosed().subscribe(response => {
      if(response) {
        // Notificacition: email sentd
        this.authService.resetPassword(this.master, this.input.extra.email_field, this.detail).subscribe();
      }
    });
  }

  emitChange() {
    this.change.emit(null);
  }
}
