import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class MomentService {

  constructor() { }

  get moment() {
    return moment;
  }
}
