import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../core/services/config/config.service';

@Component({
  selector: 'wk-master-landing',
  templateUrl: './master-landing.component.html',
  styleUrls: ['./master-landing.component.scss']
})
export class MasterLandingComponent implements OnInit, OnDestroy {
  config: ISectionConfig;
  relations: IRelation[];
  data;
  locale: string;
  routeSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.locale = this.configService.localeDefault;
    this.routeSubscription = this.activatedRoute
                                    .data
                                    .subscribe(data => {
                                      this.data = data.data;
                                      this.relations = data.relations;
                                      this.config = data.config;
                                    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
