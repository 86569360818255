import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from './admin/admin.component';
import { AuthService } from './auth/auth-service/auth.service';
import { AuthModule } from './auth/auth.module';
import { BreadcrumbsComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { ErrorsModule } from './errors/errors.module';
import { CanDeactivateGuard } from './guards/candeactivate.guard';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { LoadingModule } from './loading/loading.module';
import { QuickpanelComponent } from './quickpanel/quickpanel.component';
import { MasterDetailConfigResolverService } from './resolvers/master-detail-config.resolver';
import { MasterDetailDataResolverService } from './resolvers/master-detail-data.resolver';
import { MasterDetailRelationsResolverService } from './resolvers/master-detail-relations.resolver';
import { ScrollbarModule } from './scrollbar/scrollbar.module';
import { ConfigService } from './services/config/config.service';
import { FilesService } from './services/files/files.service';
import { LoggingService } from './services/logging/logging.service';
import { MasterDetailService } from './services/master-detail/master-detail.service';
import { MomentService } from './services/moment/moment.service';
import { NotificationService } from './services/notification/notification.service';
import { OnlineService } from './services/online/online.service';
import { TranslationService } from './services/translation/translation.service';
import { UserService } from './services/user/user.service';
import { customHttpProvider } from './services/wk-http/wk-http.service';
import { WkqlService } from './services/wkql/wkql.service';
import { IconSidenavDirective } from './sidenav/icon-sidenav.directive';
import { MediaReplayService } from './sidenav/mediareplay/media-replay.service';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavService } from './sidenav/sidenav.service';
import { SearchBarComponent } from './toolbar/search-bar/search-bar.component';
import { SearchComponent } from './toolbar/search/search.component';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarUserButtonComponent } from './toolbar/toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ClickOutsideDirective } from './utils/click-outside.directive';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FlexLayoutModule,
    FormsModule,
    ScrollbarModule,
    ErrorsModule,
    AuthModule,
    LoadingModule,
  ],
  declarations: [
    SidenavComponent,
    SidenavItemComponent,
    IconSidenavDirective,
    SearchComponent,
    BreadcrumbsComponent,
    AdminComponent,
    QuickpanelComponent,
    ToolbarComponent,
    ToolbarUserButtonComponent,
    ClickOutsideDirective,
    SearchBarComponent,
    ToolbarNotificationsComponent,
    LoadingOverlayComponent,
  ],
  providers: [
    SidenavService,
    MediaReplayService,
    BreadcrumbService,
    customHttpProvider,
    ConfigService,
    UserService,
    LoggingService,
    MasterDetailService,
    MasterDetailDataResolverService,
    MasterDetailConfigResolverService,
    MasterDetailRelationsResolverService,
    MomentService,
    NotificationService,
    OnlineService,
    TranslationService,
    AuthService,
    FilesService,
    WkqlService,
    CanDeactivateGuard,
  ],
  exports: [
    // Modules
    ErrorsModule,
    LoadingModule,
    // Components
    SidenavComponent,
    SidenavItemComponent,
    SearchComponent,
    BreadcrumbsComponent,
    AdminComponent,
    QuickpanelComponent,
    ToolbarComponent,
    ToolbarUserButtonComponent,
    SearchBarComponent,
    ToolbarNotificationsComponent,
    LoadingOverlayComponent,
    // Directives
    IconSidenavDirective,
    ClickOutsideDirective,
  ],
})
export class CoreModule { }
