import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormsService } from './services/forms.service';


@Component({
  selector: 'wk-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    FormsService,
  ]
})
export class FormComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  model: any;
  @Input()
  config: IDetailFormConfig[];
  @Input()
  relations: IRelations = {};
  @Input()
  locales: ILocale[];
  @Input()
  name: string;
  @Input()
  parent;
  @Input()
  parentForm: FormGroup;


  @Output()
  valid: EventEmitter<boolean> = new EventEmitter();
  @Output()
  change: EventEmitter<boolean> = new EventEmitter();
  @Output()
  errors: EventEmitter<Object> = new EventEmitter();

  localeDefault: ILocale;
  activeLocale: string;
  formSubscription: Subscription;
  options: string[] = [];
  sortablejsOptions;

  constructor(
    private formsService: FormsService,
  ) {}

  ngOnInit() {
    this.setupForm();
    this.config.forEach(input => {
      if (input.type === 'option') { this.options.push(input.key) };
    });
    this.sortablejsOptions = {
      onUpdate: (event: any) => {
        // TODO: Handle change index of the file
        // (already implemented in another branch?)
      }
    };
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  ngOnChanges(changes) {
    this.setupForm();
  }

  setupForm() {
    this.localeDefault = this.locales.find(locale => locale.default);
    this.activeLocale = this.localeDefault.locale;

    // If this form has a parent with table (ITableRef), it has been loaded
    // from inside a wk-master (wk-master > wk-detail (in a Dialog) > wk-form > wk-master) component
    // so we need to:
    //  - Disable the input that represents the parent because we can not change the parent.
    //  - If the form doesn't have model, we need to set the model to the input that represents
    // the parent, because this item needs to be related to this parent.
    if (this.parent && this.parent.table) {
      this.config = this.config.map(input => {
        if (input.key === this.parent.table.childProp) {
          input = {
            ...input,
            extras: {
              ...input.extras,
              disabled: true,
            }
          }
        }
        return input;
      });

      if (!this.model) {
        this.model = {[this.parent.table.childProp]: this.parent.table.parent_value};
      }
    }

    const form = this.formsService.toFormGroup(this.config, this.locales, this.model, this.relations);

    this.parentForm.contains(this.name) ?
          this.parentForm.setControl(this.name, form) :
          this.parentForm.addControl(this.name, form);

    this.formSubscription = this.parentForm
                                  .get(this.name)
                                  .valueChanges
                                  .subscribe(formValue => {
                                    const formValueCopy = {...formValue};

                                    Object.keys(formValueCopy).forEach((key, index) => {
                                      if (formValueCopy[key] && this.options.indexOf(key) !== -1) {
                                        if (formValueCopy[key] && !Array.isArray(formValueCopy[key])) {
                                          formValueCopy[key] = [formValueCopy[key]];
                                        }
                                        this.config.forEach(input => {
                                          if (input.key === key && input.extras.multiple && formValueCopy[key]) {
                                            formValueCopy[key] = formValueCopy[key]
                                                                  .reduce((filtered, multiItem, i) => {
                                                                    if (multiItem) {
                                                                      const multiValue = this.relations[input.extras.table.path]
                                                                                                .filter(relation => relation[input.extras.table.itemValue] === formValueCopy[key][i])
                                                                                                .map(value => value._id)[0];
                                                                      filtered.push(multiValue);
                                                                    }
                                                                    return filtered;
                                                                  }, []);
                                          }
                                        });
                                      }
                                    })
                                    this.change.emit(formValueCopy);
                                    this.valid.emit(this.parentForm.get(this.name).valid);
                                    this.errors.emit(this.formsService.getErrors(this.parentForm.get(this.name)));
                                  });
  }

  emitErrors() {
    this.errors.emit(this.formsService.getErrors(this.parentForm.get(this.name)));
  }

  changeLocale(locale) {
    this.activeLocale = locale;
  }
}
