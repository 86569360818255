import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SimpleChanges } from '@angular/core/src/metadata/lifecycle_hooks';
import { MatDialog, MatPaginator, MatSnackBar, MatTableDataSource, PageEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { DeleteConfirmDialogComponent } from '../../shared/master/delete-confirm-dialog/delete-confirm-dialog.component';
import { fadeAnimation } from './../animations/animations';
import { TableService } from './services/table.service';



@Component({
  selector: 'wk-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    TableService,
  ],
  animations: [
    trigger('fade', [
      transition('void => *', [
        useAnimation(fadeAnimation, {
          delay: 0,
          params: { from: 0, to: 1, time: '500ms' },
        })
      ])
    ])
  ]
})
export class TableComponent implements OnInit, OnChanges {
  @Input()
  data: any[];
  @Input()
  master: string;
  @Input()
  config: ISectionConfig;
  @Input()
  locale: string;

  @Output()
  delete: EventEmitter<any> = new EventEmitter();
  @Output()
  modification: EventEmitter<any> = new EventEmitter();
  @Output()
  edit: EventEmitter<any> = new EventEmitter();

  tableData$: Observable<any>;

  relations: IRelations;
  relationsArray = [];
  collection: MatTableDataSource<any[]>;
  headers: string[];
  columns: IMasterColumnConfig[];
  rows: any[];

  page = 1;
  pageSize = 10;
  totalPages: number;
  pagesArray: any[];
  pageSizeOptions = [5, 10, 20];
  pagesNumbers: number[];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  searchFilterCriteria: string;
  selectFiltersCriteria = {};
  sortCriteria: string;
  oldSortCriteria: string;
  orderDescendent: boolean;

  constructor(
    private tableService: TableService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.tableService
            .table$
            .subscribe(table => {
              this.headers = table.columns.map(header => header.name);
              this.columns = table.columns;
              this.rows = table.rows;
              this.page = 1;

              this.paginate(this.rows);
              if (table.relations) {
                this.relations = table.relations;
                this.createRelationsArray(this.relations);
              }
            });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.tableService.setTable(this.config, changes.data.currentValue);
    }
  }

  emitEdit(item?) {
    const itemToEdit = {
      master: this.master,
      data: item,
      config: this.config,
    };

    this.edit.emit(itemToEdit);
  }

  createRelationsArray(relations) {
    this.relationsArray = [];
    Object.keys(relations).forEach((relation) => {
      const relationToPush = {
        id: relation,
        options: [],
      };
      Object.keys(relations[relation]).forEach((option) => {
        let optionToPush;
        if (typeof relations[relation][option] === 'object') {
          optionToPush = {
            name: relations[relation][option][this.locale],
            value: option,
          };
        } else {
          optionToPush = {
            name: relations[relation][option],
            value: option,
          };
        }
        relationToPush.options.push(optionToPush);
      });
      this.relationsArray.push(relationToPush);
    });
  }

  paginate(rows: any[], pageToShow?: number) {
    const page = pageToShow || this.page;
    const start = (page - 1) * this.pageSize;
    const end = start + this.pageSize;
    const rowPage = rows.slice(start, end);
    this.collection = new MatTableDataSource(rowPage);
    this.totalPages = Math.ceil(rows.length / this.pageSize);
    this.pagesArray = new Array(this.totalPages);
    this.pagesNumbers = new Array<number>(this.totalPages);
  }

  sortTable(sortCriteria: string) {
    this.tableService.sort(sortCriteria, this.locale);
  }

  filterTable(searchFilterCriteria: string, selectFiltersCriteria: Object, locale: string) {
    this.tableService.filter(searchFilterCriteria, selectFiltersCriteria, locale);
  }

  trackBy(index: number, row) {
    return row && row._id;
  }

  refreshTable() {
    this.tableService.refreshTable();
  }

  setPage(page) {
    this.page = page;
    this.paginate(this.rows);
  }

  emitDelete(detail: number) {
    // TODO: Confirm delete
    const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, {
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.delete.emit(detail);
      } else {
        this.snackBar.open('Operación cancelada', 'Close', {
          duration: 1500
        });
      }
    });
  }

  emitModification(detail, key?: string) {
    let detailToEmit: {[key: string]: string} = {};
    if (key) {
      detailToEmit._id = detail._id;
      detailToEmit[key] = detail[key];
    } else {
      detailToEmit = {...detail};
    }
    this.modification.emit(detailToEmit);
  }

  pageEvent(event: EventEmitter<PageEvent>) {
    const e: any = event; // Cast to prevent warning
    this.page = e.pageIndex + 1;
    this.pageSize = e.pageSize;
    this.paginate(this.rows);
  }

  export() {
    const ids = this.rows.map(row => row._id);
    this.tableService.exportData(this.master, ids).subscribe(data => {
      if (data.url) {
        window.open(data.url, '_blank');
      }
    });
  }
}
