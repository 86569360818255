
import {of as observableOf,  Observable } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { EncriptService } from './../../auth/encript-service/encript.service';
import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoggingService {

  constructor(
    private userService: UserService,
    private encriptService: EncriptService,
  ) { }

  log(error) {
    // TODO: Send errors to server
    // TODO: Include user and app id
    error.time = new Date().getTime();
    error.id = this.encriptService.getUuid();
    error.user = this.userService.getUser() && this.userService.getUser().username || '';
    return observableOf(error);
  }

}
