import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, FormGroup } from '@angular/forms';
import { CustomService } from './../../features/custom/custom.service';


@Component({
  selector: 'wk-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CustomComponent, multi: true }
  ]
})
export class CustomComponent implements OnInit, OnChanges {
  onTouched: Function;
  onModelChange: Function;
  private portalContent: ComponentRef<any>;

  @Output()
  change = new EventEmitter();
  @Input()
  component: string;
  @Input()
  config: string;
  @Input()
  formControl: FormControl;
  @Input()
  formParent: FormGroup;
  @Input()
  parentModel: any;

  @ViewChild('portalHost')
  portalHost: ElementRef;

  constructor(
    private customService: CustomService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
  ) { }

  ngOnInit() {
    // Get the element whre the element will be attached
    const portalHost = this.portalHost.nativeElement;
    const componentClass = this.customService[this.component];
    // Get the component factory for the custom component to be attached
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    // Generate an instance of the custom component to be attached
    this.portalContent = componentFactory.create(this.injector, [], portalHost);
    this.portalContent.instance.formControl = this.formControl;
    // Pass the parent form to the custom component
    this.portalContent.instance.formParent = this.formParent;
    // Pass the parent model to the custom component
    this.portalContent.instance.parentModel = this.parentModel;
    // Pass input data to the component
    if (this.config) {
      this.portalContent.instance.config = this.config;
    }
    // Subcribe to the custom component changes to emit them (change event)
    this.portalContent
            .instance
            .change
            .subscribe(change => this.emitChange(change));
    // Attach to the component to Angular's component tree for dirty checking
    // Defer attach with setTimeout to avoid 'ExpressionChangedAfterItHasBeenCheckedError' Error
    setTimeout(() => {
      this.appRef.attachView(this.portalContent.hostView);
    }, 0);
    // Update the custom component CSS classes to reflect the input status
    this.formControl.valueChanges.subscribe(changes => {
      const customElement = this.portalContent.location.nativeElement.firstChild;
      this.formControl.pristine ? customElement.classList.add('custom-pristine') : customElement.classList.remove('custom-pristine');
      this.formControl.touched ? customElement.classList.add('custom-touched') : customElement.classList.remove('custom-touched');
      this.formControl.untouched ? customElement.classList.add('custom-untouched') : customElement.classList.remove('custom-untouched');
      this.formControl.dirty ? customElement.classList.add('custom-dirty') : customElement.classList.remove('custom-dirty');
      this.formControl.valid ? customElement.classList.add('custom-valid') : customElement.classList.remove('custom-valid');
      this.formControl.invalid ? customElement.classList.add('custom-invalid') : customElement.classList.remove('custom-invalid');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.portalContent) {
      if (changes.config && changes.config.currentValue) {
        this.portalContent.instance.config = changes.config.currentValue;
      }

      if (changes.formParent) {
        this.portalContent.instance.formParent = changes.formParent.currentValue;
      }

      if (changes.parentModel) {
        this.portalContent.instance.parentModel = changes.parentModel.currentValue;
      }

      // If the custom component has ngOnChanges, call it (Backwards compatibility.
      // Some previous might not have it).
      if (this.portalContent.instance.ngOnChanges) {
        this.portalContent.instance.ngOnChanges({...changes});
      }
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  registerOnChange(fn) {
    this.onModelChange = fn;
  }

  writeValue(value) {
    this.portalContent.instance.value = value;
  }

  emitChange(change) {
    this.onTouched();
    this.onModelChange(change);
    this.change.emit(change);
  }
}
