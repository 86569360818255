
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../core/services/config/config.service';

@Component({
  selector: 'wk-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: MultiSelectComponent, multi: true }
  ]
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  name: string;
  @Input()
  required: boolean;
  @Input()
  options: IOption[];

  @Output()
  change = new EventEmitter();
  @Output()
  blur = new EventEmitter();

  value: IOption[];
  selectable = true;
  removable = true;
  addOnBlur = true;

  activeLocale$: Observable<string>;
  filteredOptions$: Observable<IOption[]>;
  activeLocale: string;
  separatorKeysCodes = [ENTER, COMMA];
  optionsControl: FormControl;
  optionOptions: IOption[] = [];
  onTouch: Function;
  onChange: Function;
  isDisabled: boolean;
  optionsForm: FormGroup;

  selectedOptions = [];


  @ViewChild('optionsControlInput')
  optionsControlInput: ElementRef;

  constructor(
    private configService: ConfigService,
    private formBuilder: FormBuilder,
  ) {}

  writeValue(optionIds) {
    if (optionIds) {
      optionIds.forEach(optionId => {
        const isAlreadySelected = this.selectedOptions.some(option => option._id === optionId);

        if (!isAlreadySelected) {
          const optionToPush = this.options.find(option => option._id === optionId);
          this.selectedOptions.push(optionToPush);
        }
      });
    } else {
      this.selectedOptions = [];
    }

    if(!this.optionsForm) {
      this.buildForm();
    }
    else {
      //realmente deberia ser un reset. Pero se requiere seguir manteniendo la seleccion actual segun marti.
      this.emitChange(); 
    }
  }

  registerOnTouched(fn) {
    this.onTouch = fn;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
    this.activeLocale$ = this.configService
                                .localeDefault$.pipe(
                                map((activeLocale) => {
                                  this.activeLocale = activeLocale;
                                  return activeLocale;
                                }));
    this.optionsControl = new FormControl(this.value);
    this.filteredOptions$ = this.optionsControl
                                        .valueChanges.pipe(
                                        map(value => {
                                          if (value === undefined || typeof value !== 'string') { value = ''};
                                          const query = value.toLowerCase();
                                          return this.options
                                                        .filter(option => !this.selectedOptions.find(opt => opt._id === option._id))
                                                        .filter(option => {
                                                          const optionName = option.name[this.activeLocale].toLowerCase();
                                                          return optionName.indexOf(query) !== -1;
                                                        });
                                        }));
  }

  buildForm() {
    this.optionsForm = this.formBuilder.group({
      checkboxes: this.formBuilder.array([]),
    })

    // Eliminamos del array las optiones que no existen en BBDD
    this.selectedOptions = this.selectedOptions.filter(option => option !== undefined && option !== null);
    this.options.forEach(option => {
      const optionIsTrue = this.selectedOptions.find(opt => {
        return opt && opt._id === option._id;
      }) !== undefined;
      const checkboxesArray = this.optionsForm.get('checkboxes') as FormArray;

      checkboxesArray.push(this.formBuilder.control(optionIsTrue));
    });
  }

  emitChange() {
    const selectedOptions = this.selectedOptions.map(option => option._id);
    this.onChange(selectedOptions);
  }

  addOption(option) {
    this.selectedOptions.push(option);
    this.emitChange();
  }

  removeOption(option) {
    const chipIndex = this.selectedOptions.findIndex(opt => opt._id === option._id);
    this.selectedOptions.splice(chipIndex, 1);
    this.emitChange();
  }

  manageOption(checked, option) {
    // Manage Checkboxes
    const formArrayIndex = this.options.findIndex(opt => opt._id === option._id);
    const formArray = this.optionsForm.get('checkboxes') as FormArray;
    formArray.at(formArrayIndex).setValue(checked);
    // Manage Chips
    if (checked) {
      this.addOption(option);
    } else {
      this.removeOption(option);
    }
  }

  onKeyDown(event) {
    // If Arrow Down && no value, show all options
    const optionsControlValue = this.optionsControl.value;
    if (event.keyCode === 40 && !optionsControlValue) {
      this.optionsControl.setValue('');
    }
  }

  resetInput() {
    this.optionsControl.reset();
    // Remove text from input
    this.optionsControlInput.nativeElement.value = '';
  }
}
