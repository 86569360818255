import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { EncriptService } from '../../../core/auth/encript-service/encript.service';

@Component({
  selector: 'wk-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private encriptService: EncriptService,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      password1: ['', Validators.required],
      password2: ['', Validators.required],
    })
  }

  changePassword() {
    if(this.form.value.password1 === this.form.value.password2) {
      this.dialogRef.close(this.encriptService.sha(this.form.value.password1))
    }
  }

}
