import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';

@Component({
  selector: 'wk-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {
  url: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ImagePreviewComponent>,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.url = this.data;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  copyToClipboard() {
    this.snackBar.open('Method not implemented yet', 'Close', {
      duration: 1500
    });
    this.dialogRef.close();
  }
}
