/** CUSTOM HANDLER DIRECTIVE EXPLANATION */
/**
 * This directive is in charge of adding custom logic to our FormComponent.
 * To add custom logic follow the next steps:
 *
 * 1 - Create a service with:
 *      - rendererFactory injected:
 *          constructor( private rendererFactory: RendererFactory2) {
 *            this.renderer = this.rendererFactory.createRenderer(null, null);
 *          }
 *        The service needs to inject the Renderer2 and instantiate it (so you can use it).
 *
 *      - init method:
 *        init(form, domElement) { ... }
 *        This method will receive the dom element where the directive
 *        is (so you can query it to find control, show/hide them...),
 *        and the form (so you set values, enable/disable...)
 *
 * 2 - Add it to the folder app/features/custom/modules/[project_custom_module]/services
 *
 * 3 - Import the service in the CustomService and add it to the constructor:
 *     (The custom service is in charge of providing the service to the directive).
 *
 *      import { CustomLogicService } from './services/custom-logic.service';
 *
 *      @Injectable()
 *       export class CustomService {
 *         constructor(
 *           private customLogicService: CustomLogicService,
 *         ) {}
 *
 *         getInitLogicForCustomForm(customFormServiceName) {
 *           return this[customFormServiceName];
 *         }
 *       }
 *
 * ** The custom handler service name comes from the customHandler prop of the ISectionConfig
 * loaded from the server with the MasterDetailService and its passed to the detail component.
 *
 * EXAMPLE of custom handler service:
 * import { Injectable, Renderer2, RendererFactory2, ElementRef } from '@angular/core';
 * import { FormGroup } from '@angular/forms';
 *
 * @Injectable({
 *   providedIn: 'root'
 * })
 * export class CustomFormService {
 *   private renderer: Renderer2;
 *
 *   constructor(
 *     private rendererFactory: RendererFactory2,
 *   ) {
 *     this.renderer = this.rendererFactory.createRenderer(null, null);
 *   }
 *
 *   init(form, domElement) {
 *     form
 *       .get('Informacion')
 *       .get('energetico_tipo')
 *       .valueChanges
 *       .subscribe(type => {
 *         // Query for an input (all the inputs have an id with its input.key value)
 *         const child = domElement.nativeElement.querySelector('#name');
 *
 *          // Conditionally show/hide, enable/disable or set values
 *         if (type === '5b2791f8a72de9effe664e5f') {
 *           this.renderer.setStyle(child, 'display', 'none');
 *           form.get('Informacion.cups').setValue(10);
 *           form.get('Informacion.hotel').disable();
 *         } else {
 *           this.renderer.setStyle(child, 'display', 'flex');
 *           form.get('Informacion.hotel').enable();
 *         }
 *       });
 *   }
 * }
 */


import { FormGroupDirective, FormGroup } from '@angular/forms';
import { Input, Directive, ElementRef, OnInit, OnChanges, Self } from '@angular/core';
import { CustomService } from '../../../features/custom/custom.service';

@Directive({
  selector: '[wkCustomHandler]'
})
export class CustomHandlerDirective implements OnInit, OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input('wkCustomHandler')
  handlerConfig: any;

  form: FormGroup;

  constructor(
    private element: ElementRef,
    private formDirective: FormGroupDirective,
    private customService: CustomService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.form = this.formDirective.form;
      if (this.hasCustomHandler()) {
        this.initCustomLogic(this.handlerConfig, this.form, this.element);
      }
    }, 0);
  }

  ngOnChanges(changes) {
    if (this.hasCustomHandler() && this.form && changes.service && changes.service.currentValue) {
      this.initCustomLogic(changes.service.currentValue, this.form, this.element);
    }
  }

  initCustomLogic(handlerConfig, form, DOMElement) {
    this.customService
          .getInitLogicForCustomForm(handlerConfig.config.customHandler)
          .init(form, DOMElement, handlerConfig);
  }

  hasCustomHandler(): boolean {
    return this.handlerConfig && this.handlerConfig.config && this.handlerConfig.config.customHandler;
  }
}
