// This MasterComponent reproduces the master-detail pattern
// There is a TableComponent with a list of all the items (details) of a collection (master)
// When we click in a row, a dialog with a DetailComponent is opened passing to it all the
// data of the detail to be edited.
// - DetailComponent is in charge of saving the changes to the detail, emiting the changes
//   through the afterSave$ observable.
// - MasterComponent listens to the DetailComponent.afterSave$ observable to refresh its master
//   list every time a detail is saved.
//
// MasterComponent can work in 2 ways:
// 1 - Inside a MasterLanding (Routed component) where it receives all its data and config from
//     Inputs (tableConfig, relations, locale, data). This data has been resolved in the route.
// 2 - Inside a FormComponent (when input.type === 'master'), in this case it just receives its
//     parent's data and table and its config as Inputs (config, parent) and then it has to
//     resolve its tableConfig, relations, data from the server (setUpMasterConfig()).
//
// Workflow: The workflow is then:
// 1 - MasterComponent (shows master table) >>>
// 2 - TableComponent (emits click on row with the detail data) >>>
// 3 - MasterComponent opens detail in MatDialog with the detail and config >>>
// 4 - MatDialog opens DetailComponent with and pass the data and config >>>
// 5 - DetailComponent loads FormComponent with data and config >>>
// 6 - FormComponent is in charge of editing the detail's data
//
// ** Recursion: A FormComponent can contain a MasterComponent itself, it would start
//               at the step one again, creating a nested MasterComponent tree.

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { forkJoin, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ConfigService } from './../../core/services/config/config.service';
import { MasterDetailService } from './../../core/services/master-detail/master-detail.service';
import { DetailComponent } from './../detail/detail.component';



@Component({
  selector: 'wk-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss'],
})
export class MasterComponent implements OnInit, OnChanges, OnDestroy {
  // This component takes its configuration and data from Inputs (tableConfig, relations,
  // locale, data) when it is used inside master-landing.component.html (where data and relations
  // have been resolved in the route) but when it is used independently inside
  // form.component.html it takes its configuration and parent fron Inputs (config, parent), and
  // needs to get its data and relations from the server (because they haven't been resolved,
  // it is not in a route).
  @Input()
  config: IMasterColumnConfig;
  @Input()
  tableConfig: ISectionConfig;
  @Input()
  relations: IRelations;
  @Input()
  locale: string;
  @Input()
  data;
  @Input()
  parent: string;

  master: string;
  query;
  dialogRef: MatDialogRef<DetailComponent>;

  private unsubscribe$ = new Subject();

  constructor(
    private masterDetailService: MasterDetailService,
    private configService: ConfigService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    // If the component is loaded as part of a form (as a child), then it receives
    // its config from the 'config' Input, receives the 'parent' as Input too
    // and needs to get its data and relations from the server (because they
    // haven't been resolved, it is not in a route).
    if (this.config) {
      this.setUpMasterConfig(this.config);
    } else {
      this.master = this.tableConfig.path;
    }
  }

  ngOnChanges(changes) {
    // If this master-component has no parent (Input), because we are creating a
    // new parent element (and this far it has no ID to associate this master with),
    // when the user saves the parent, we have to refresh this master config so we
    // can query the server with the parent id to have this master options only for
    // this parent (ie: If Parent = Clients and Master = Hotels >>> this master would
    // show the hotels associated with this Client ID and would allow to create/associate
    // Hotels only for this Client).
    if (changes.parent) {
      this.setUpMasterConfig(this.config);
    }

    if (changes.tableConfig) {
      this.master = changes.tableConfig.currentValue.path;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setUpMasterConfig(config) {
    this.master = config.extras.table.path;
    this.tableConfig = this.configService.getSectionConfig(this.master);
    // We need this query to filter the results from the parent/master table.
    this.query = this.parent ?
                  {[config.extras.table.childProp]: this.parent[config.extras.table.parentProp]} :
                  null;

    forkJoin(
      this.masterDetailService.getMaster(this.master, this.query),
      this.masterDetailService.getRelations(this.master),
    )
    .subscribe(results => {
      this.data = results[0];
      this.relations = results[1];
    })
  }

  deleteDetail(detail) {
    this.masterDetailService
            .deleteDetail(this.master, detail)
            .pipe(
              switchMap(() => this.masterDetailService.getMaster(this.master, this.query))
            )
            .subscribe(masterData => this.data = masterData);
  }

  saveDetail(detail) {
    this.masterDetailService
            .updateDetail(this.master, detail)
            .pipe(
              switchMap(() => this.masterDetailService.getMaster(this.master, this.query))
            )
            .subscribe(masterData => this.data = masterData);
  }

  openDetailDialog(detail?) {
    const dialogData = {
      detail: detail.data ? {...detail.data} : null,
      config: detail.config ? {...detail.config} : null,
      master: detail.master,
      relations: this.relations ? {...this.relations} : null,
      table: this.config ?
               {
                ...this.config.extras.table,
                parent_value: this.parent[this.config.extras.table.parentProp],
               } :
               null,
    }

    // Add Detail & data
    if (detail && detail.data) {
      this.masterDetailService
            .getDetail(this.master, detail.data._id)
            .subscribe(detailModel => {
              const dialogDataWithModel = {...dialogData, model: detailModel};
              this.createDetailDialog(dialogDataWithModel);
            });
    } else {
      this.createDetailDialog(dialogData);
    }
  }

  createDetailDialog(data?) {
    this.dialogRef = this.dialog.open(DetailComponent, {
      data,
      disableClose: true,
      panelClass: 'detail-dialog',
      maxWidth: '100vw',
      width: '100vw',
      height: '100vh',
    });
    const dialogDetailComponentInstance = this.dialogRef.componentInstance;

    // Emit everytime the user saves changes in
    // the detail component (opened by this popup)
    dialogDetailComponentInstance
      .afterSave$
      .pipe(
        switchMap(() => this.masterDetailService.getMaster(this.master, this.query)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(masterData => this.data = masterData);

    this.dialogRef
          .backdropClick()
          .pipe(
            switchMap(() => dialogDetailComponentInstance.canDeactivate()),
            filter(canDeactivate => canDeactivate),
            switchMap(() => this.masterDetailService.getMaster(this.master, this.query)),
            takeUntil(this.unsubscribe$),
          )
          .subscribe(masterData => {
            this.data = masterData;
            this.dialogRef.close();
          });
  }
}
