import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CustomRoutingModule } from './custom-routing.module';
import { CustomService } from './custom.service';


@NgModule({
  imports: [
    CustomRoutingModule,
    SharedModule,
  ],
  providers: [
    CustomService,
  ],
})
export class CustomModule { }
