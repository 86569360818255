import { Injectable } from '@angular/core';

import { WkHttpService } from './../wk-http/wk-http.service';

@Injectable()
export class FilesService {
  basePath = 'files';

  constructor(private wkHttpService: WkHttpService) {}

  saveAndGetDetails(file: any) {
    // FIXED: Error when upload images in static mod
    file.basePath = file.basePath.split('?')[0];
    file.section = file.section.split('?')[0];
    // ---------------------------------------------
    return this.wkHttpService.post(this.basePath, file);
  }
}
