import { Component, OnInit, ViewChild, Optional, Host, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AgmMap } from '@agm/core';

@Component({
  selector: 'wk-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent {
  zoom = 16;
  locationGroupForm: FormGroup;

  @Input()
  name: string;
  @Input()
  parentForm: FormGroup;

  @Output()
  blur = new EventEmitter();

  @ViewChild(AgmMap) public agmMap: AgmMap;
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  constructor() { }

  handleAddressChange(address: Address) {
    this.parentForm.get(this.name).setValue(this.extractData(address));
  }

  extractData(address: Address): ILocation {
    const place: ILocation = {
      address: '',
      formattedAddres: '',
      country: '',
      area: '',
      region: '',
      locality: '',
      postal_code: '',
      lat: null,
      lng: null,
    };
    place.lat = address.geometry.location.lat();
    place.lng = address.geometry.location.lng();
    place.formattedAddres = address.formatted_address;
    place.address = address.name;
    address.address_components.forEach(component => {
      if (component.types.indexOf('country') > -1) {
        place.country = component.long_name;
      }
      if (component.types.indexOf('administrative_area_level_2') > -1) {
        place.area = component.long_name;
      }
      if (component.types.indexOf('administrative_area_level_1') > -1) {
        place.region = component.long_name;
      }
      if (component.types.indexOf('locality') > -1) {
        place.locality = component.long_name;
      }
      if (component.types.indexOf('postal_code') > -1) {
        place.postal_code = component.long_name;
      }
    });
    return place;
  }

  emitBlur() {
    this.blur.emit(null);
  }
}
