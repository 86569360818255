import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../../../core/services/config/config.service';

@Pipe({
  name: 'wkTranslate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private configService: ConfigService,
  ) {}

  transform(value: any, args?: any): any {
    return typeof value === 'object' && value[this.configService.localeDefault] ?
              value[this.configService.localeDefault] :
              value;
  }
}
