import { ConfigService } from './../services/config/config.service';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MasterDetailService } from './../services/master-detail/master-detail.service';

@Injectable()
export class MasterDetailConfigResolverService implements Resolve<any> {
  constructor(
    private configService: ConfigService,
  ) {}

  resolve(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): ISectionConfig {
    const master = activeRoute.params['master'];

    return this.configService.getSectionConfig(master);
  }
}
