/**
 * CUSTOM SERVICE EXPLANATION
 * This service is in charge of:
 *  1 - Provide instances of custom components to our CustomComponent
 *      (so it can inject them dynamically).
 *  2 - Provide instances of services to our CustomHandlerDirective
 *      (so it can add custom logic to our FormComponent).
 */

/** CUSTOM COMPONENTS EXPLANATION **
 * Custom components are components that are exclusive to a particular project/branch (ie: barcelo)
 *
 * To add a custom component to a project:
 *
 *    1 - Create the component that you want to use as a custom component inside
 *        app/features/custom/modules/[project_custom_module]/components
 *
 *    2 - In order to integrate with the Angular forms, the component must implement
 *        the following interface:
 *                  export class SampleComponent {
 *                    // Value of the input (used to set the value of the custom control
 *                    // from the form.component.ts). You can use setters for advanced cases
 *                    value;
 *
 *                    // Each time this component's value changes,
 *                    // it has to emit the change
 *                    @Output()
 *                    change = new EventEmitter();
 *
 *                    emitChange(change) {
 *                      this.change.emit(change);
 *                    }
 *
 *                    // Optionally you can implement ngOnChanges to react to the
 *                    // changes of the CustomComponent container (who has the following
 *                    // @Inputs: component, config, formControl, formParent, formModel).
 *                    ngOnChanges(changes: SimpleChanges) {
 *                    }
 *                  }
 *
 *        * Example on the component's template:
 *          <input type="text" [(ngModel)]="value" (keyup)="emitChange(value)">
 *
 *        * The first element in the custom component's template will receive the
 *          CSS class of its status: .custom-valid, .custom-invalid, .custom-touched,
 *          .custom-untouched, .custom-pristine, .custom-dirty.
 *          Use this classes to style the custom component and show to the user its
 *          status (i.e: .custom-invalid { border: 2px solid red })
 *
 *    3 - Add the component to the 'entryComponents' array of the [custom_module]
 *    4 - Add the component to 'custom.service.ts'
 *
*/

/** CUSTOM SERVICES EXPLANATION **
 * Customs services are used to provide custom logic to our FormComponent through
 * the CustomHandlerDirective.
 *
 * To add a custom service to a project:
 *
 *  1 - Create a service with:
 *      - rendererFactory injected:
 *          constructor( private rendererFactory: RendererFactory2) {
 *            this.renderer = this.rendererFactory.createRenderer(null, null);
 *          }
 *        The service needs to inject the Renderer2 and instantiate it (so you can use it).
 *
 *      - init method:
 *        init(form, domElement) { ... }
 *        This method will receive the dom element where the directive
 *        is (so you can query it to find control, show/hide them...),
 *        and the form (so you set values, enable/disable...)
 *
 * 2 - Add it to the folder app/features/custom/modules/[project_custom_module]/services
 *
 * 3 - Import the service in this CustomService and add it to the constructor:
 *     (The custom service is in charge of providing the service to the directive).
 *
 *      import { CustomLogicService } from './services/custom-logic.service';
 *
 *      @Injectable()
 *       export class CustomService {
 *         constructor(
 *           private customLogicService: CustomLogicService,
 *         ) {}
 *
 *         getInitLogicForCustomForm(customFormServiceName) {
 *           return this[customFormServiceName];
 *         }
 *       }
 *
 * ** To see an EXAMPLE of a custom handler service, please go to
 *    CustomHandlerDirective code.
 */

// IMPORT THE CUSTOM COMPONENTS AND SERVICES THAT YOU WANT TO USE
import { Injectable } from '@angular/core';


@Injectable()
export class CustomService {
  // CUSTOM COMPONENTS
  // Add here the component's name as a public property, followed by the class
  // that will be loaded by the custom.component (componentName = ComponentClassName). Ie:
  // get counter() { return CounterComponent };

  // CUSTOM SERVICES (wkCustomHandler directive)
  // Inject in the constructor the custom services that you want to use.
  constructor() {}

  getInitLogicForCustomForm(customFormServiceName) {
    return this[customFormServiceName];
  }
}
