import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MasterDetailService } from './../services/master-detail/master-detail.service';




@Injectable()
export class MasterDetailDataResolverService implements Resolve<any> {
  constructor(
    private masterDetailService: MasterDetailService,
  ) {}

  resolve(activeRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const master = activeRoute.params['master'];
    const detail = activeRoute.params['detail'];
    const resolvedData = detail ?
                            this.masterDetailService.getDetail(master, detail) :
                            this.masterDetailService.getMaster(master);
    return resolvedData;
  }
}
