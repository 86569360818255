import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth-service/auth.service';
import { WkHttpService } from '../../core/services/wk-http/wk-http.service';
import { fadeInAnimation } from '../../route.animation';

@Component({
  selector: 'ms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {
    '[@fadeInAnimation]': 'true'
  },
  animations: [ fadeInAnimation ]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  hidePassword = true;
  invalidErrorMessage: boolean;
  keepMeLoggedIn: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private wkHttpService: WkHttpService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [ '', [ Validators.required, Validators.email ]],
      password: [ '', [ Validators.required ]],
    });

    this.loginForm
          .valueChanges
          .subscribe(() => {
            this.invalidErrorMessage = false;
          });
  }

  login(username: string, password: string) {
    this.authService
            .login(username, password, this.keepMeLoggedIn)
            .subscribe(
              (response) => {
                const redirectUrl = this.authService.redirectUrl || '';
                this.router.navigate([redirectUrl]);
              },
              (error) => {
                this.invalidErrorMessage = true;
              }
            );
  }

}
