import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// tslint:disable-next-line:max-line-length
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'angular-sortablejs/dist';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CustomComponent } from './custom/custom.component';
import { CanDeactivateDialogComponent } from './detail/can-deactivate-dialog/can-deactivate-dialog.component';
import { DetailComponent } from './detail/detail.component';
import { ClipboardDirective } from './directives/clipboard/clipboard.directive';
import { FileManagerComponent } from './file-manager/components/file-manager/file-manager.component';
import { FileComponent } from './file-manager/components/file/file.component';
import { ImagePreviewComponent } from './file-manager/components/image-preview/image-preview.component';
import { InputFileComponent } from './file-manager/components/input-file/input-file.component';
import { FormComponent } from './form/form.component';
import { InputTextComponent } from './input-text/input-text.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LocationComponent } from './location/location.component';
import { DeleteConfirmDialogComponent } from './master/delete-confirm-dialog/delete-confirm-dialog.component';
import { MasterComponent } from './master/master.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ChangePasswordComponent } from './password/change-password/change-password.component';
import { PasswordComponent } from './password/password.component';
import { ResetPasswordComponent } from './password/reset-password/reset-password.component';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { FilterModule } from './pipes/filter/filter.module';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { OrderPipe } from './pipes/order/order.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { SeoComponent } from './seo/seo.component';
import { TableComponent } from './table/table.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { SelectGroupComponent } from './select-group/select-group.component';
import { CustomHandlerDirective } from './directives/custom-handler/custom-handler.directive';
import { TranslatePipe } from './directives/translate/translate.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatExpansionModule,
    FilterModule,
    AgmCoreModule,
    GooglePlaceModule,
    SortablejsModule.forRoot({
      animation: 300
    }),
    AngularEditorModule,
  ],
  declarations: [
    // Components
    MasterComponent,
    DetailComponent,
    CanDeactivateDialogComponent,
    DeleteConfirmDialogComponent,
    TableComponent,
    FormComponent,
    LanguageSelectorComponent,
    FileManagerComponent,
    InputFileComponent,
    FileComponent,
    ImagePreviewComponent,
    MultiSelectComponent,
    LocationComponent,
    CustomComponent,
    PasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    InputTextComponent,
    PermissionsComponent,
    // Pipes
    KeysPipe,
    TruncatePipe,
    FileSizePipe,
    ClipboardDirective,
    PasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    SeoComponent,
    OrderPipe,
    SelectGroupComponent,
    CustomHandlerDirective,
    TranslatePipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatInputModule,
    MatTabsModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatMenuModule,
    MatGridListModule,
    MatCardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSliderModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatExpansionModule,
    FilterModule,
    AgmCoreModule,
    GooglePlaceModule,
    SortablejsModule,
    AngularEditorModule,
    // Components
    MasterComponent,
    DetailComponent,
    CanDeactivateDialogComponent,
    DeleteConfirmDialogComponent,
    TableComponent,
    FormComponent,
    LanguageSelectorComponent,
    FileManagerComponent,
    InputFileComponent,
    FileComponent,
    ImagePreviewComponent,
    MultiSelectComponent,
    CustomComponent,
    PasswordComponent,
    SeoComponent,
    InputTextComponent,
    PermissionsComponent,
    SelectGroupComponent,
    // Pipes
    KeysPipe,
    TruncatePipe,
    FilterPipe,
    OrderPipe,
    CustomHandlerDirective,
    TranslatePipe,
    // Directives
    ClipboardDirective,
  ],
  entryComponents: [
    DetailComponent,
    DeleteConfirmDialogComponent,
    CanDeactivateDialogComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ImagePreviewComponent,
  ]
})
export class SharedModule { }
