import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wk-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent {
  @Input()
  parentForm: FormGroup;
  @Input()
  config: IDetailFormConfig;
  @Input()
  locales: ILocale[];
  @Input()
  activeLocale: string;

  @Output()
  blured= new EventEmitter();
  @Output()
  localeChange= new EventEmitter();

  emitLocaleChange(locale) {
    this.localeChange.emit(locale);
  }

  emitBlured() {
    this.blured.emit();
  }
}
