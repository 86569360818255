import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './core/admin/admin.component';
import { AuthGuard } from './core/auth/auth-guard/auth.guard';
import { ErrorsComponent } from './core/errors';
import { CanDeactivateGuard } from './core/guards/candeactivate.guard';
import { AdminResolverService } from './core/resolvers/admin.resolver';
import { MasterDetailConfigResolverService } from './core/resolvers/master-detail-config.resolver';
import { MasterDetailDataResolverService } from './core/resolvers/master-detail-data.resolver';
import { MasterDetailRelationsResolverService } from './core/resolvers/master-detail-relations.resolver';
import { ForgotPasswordComponent } from './features/forgot-password/forgot-password.component';
import { LoginComponent } from './features/login/login.component';
import { MasterLandingComponent } from './features/master-landing/master-landing.component';
import { DetailComponent } from './shared/detail/detail.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'error',
    component: ErrorsComponent
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      appConfig: AdminResolverService,
    },
    children: [
      {
        path: 'billing',
        loadChildren: 'app/features/billing/billing.module#BillingModule',
      },
      {
        path: 'custom',
        loadChildren: 'app/features/custom/custom.module#CustomModule'
      },
      {
        path: 'static/:master/:detail',
        component: DetailComponent,
        resolve: {
          config: MasterDetailConfigResolverService,
          data: MasterDetailDataResolverService,
          relations: MasterDetailRelationsResolverService,
        },
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':master',
        component: MasterLandingComponent,
        resolve: {
          config: MasterDetailConfigResolverService,
          data: MasterDetailDataResolverService,
          relations: MasterDetailRelationsResolverService,
        }
      },
    ],
  },
  { path: '**', component: ErrorsComponent, data: { error: 404 } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }
