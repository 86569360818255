import { Component, OnInit } from '@angular/core';

import { ConfigService } from './../../core/services/config/config.service';
import { TranslationService } from '../../core/services/translation/translation.service';

@Component({
  selector: 'wk-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  defaultLanguage: string;
  locales: ILocale[];

  constructor(
    private translationService: TranslationService,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.defaultLanguage = this.translationService.translator.getDefaultLang();
    this.locales = this.configService.locales;
  }

  changeLanguage(lang) {
    this.translationService.changeLang(lang);
  }
}
