import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../services/config/config.service';
import { UserService } from '../services/user/user.service';
import { SidenavItem } from './sidenav-item/sidenav-item.model';

@Injectable()
export class SidenavService {

  user: IUser;
  sections: ISectionConfig[];
  groups: IGroupConfig[];
  private _itemsSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _items: SidenavItem[] = [ ];
  items$: Observable<SidenavItem[]> = this._itemsSubject.asObservable();

  private _currentlyOpenSubject: BehaviorSubject<SidenavItem[]> = new BehaviorSubject<SidenavItem[]>([]);
  private _currentlyOpen: SidenavItem[] = [ ];
  currentlyOpen$: Observable<SidenavItem[]> = this._currentlyOpenSubject.asObservable();

  isIconSidenav: boolean;

  constructor(
    snackbar: MatSnackBar,
    private configService: ConfigService,
    private userService: UserService,
  ) {}

  initMenu() {
    const menus = {};
    this._items = [];
    this.sections = this.configService.sections;
    this.groups = this.configService.groups;
    this.user = this.userService.getUser();
    this.sections.forEach(section => {
      if (section.visible_sidebar) {

        if (!menus[section.group]) {
          menus[section.group] = this.addItem(this.groups[section.group].name,
                                        this.groups[section.group].icon, null, 1,
                                        this.getNumberOfChilds(section.group));
        }

        let route = '/';
        switch (section.type) {
          case 'dynamic':
            route += section.path;
          break;
          case 'custom':
            route += section.customPath;
          break;
          default:
            route += `static/${section.path}/${section._id}`;
        }
        this.addSubItem(menus[section.group], section.name, section.icon, route, 1);
      }
    });
  }

  addItem(name: string, icon: string, route: any, position: number, badge?: string, badgeColor?: string, customClass?: string) {
    const item = new SidenavItem({
      name: name,
      icon: icon,
      route: route,
      subItems: [ ],
      position: position || 99,
      badge: badge || null,
      badgeColor: badgeColor || null,
      customClass: customClass || null
    });

    this._items.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  addSubItem(parent: SidenavItem, name: string, icon: string, route: any, position: number) {
    const item = new SidenavItem({
      name: name,
      route: route,
      parent: parent,
      icon: icon,
      subItems: [ ],
      position: position || 99
    });

    parent.subItems.push(item);
    this._itemsSubject.next(this._items);

    return item;
  }

  removeItem(item: SidenavItem) {
    const index = this._items.indexOf(item);
    if (index > -1) {
      this._items.splice(index, 1);
    }

    this._itemsSubject.next(this._items);
  }

  isOpen(item: SidenavItem) {
    return (this._currentlyOpen.indexOf(item) !== -1);
  }

  toggleCurrentlyOpen(item: SidenavItem) {
    let currentlyOpen = this._currentlyOpen;
    if (this.isOpen(item)) {
      if (currentlyOpen.length > 1) {
        currentlyOpen.length = this._currentlyOpen.indexOf(item);
      } else {
        currentlyOpen = [ ];
      }
    } else {
      currentlyOpen = this.getAllParents(item);
    }

    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  getAllParents(item: SidenavItem, currentlyOpen: SidenavItem[] = [ ]) {
    currentlyOpen.unshift( item );

    if (item.hasParent()) {
      return this.getAllParents(item.parent, currentlyOpen);
    } else {
      return currentlyOpen;
    }
  }

  getNumberOfChilds(group: string) {
    let n = 0;
    this.sections
            .filter(section => {
              return (!this.user.permissions || !this.user.permissions.groups_deny || this.user.permissions.groups_deny.indexOf(section.path) === -1) && (!this.user.permissions || !this.user.permissions.tables_deny || this.user.permissions.tables_deny.indexOf(section.path) === -1);
            })
            .forEach(section => {
              if (section.group === group) {
                n++;
              }
            });
    return n.toString();
  }

  nextCurrentlyOpen(currentlyOpen: SidenavItem[]) {
    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  nextCurrentlyOpenByRoute(route: string) {
    let currentlyOpen = [ ];

    const item = this.findByRouteRecursive(route, this._items);

    if (item && item.hasParent()) {
      currentlyOpen = this.getAllParents(item);
    } else if (item) {
      currentlyOpen = [item];
    }

    this.nextCurrentlyOpen(currentlyOpen);
  }

  findByRouteRecursive(route: string, collection: SidenavItem[]) {
    let result = _.find(collection, { 'route': route });

    if (!result) {
      _.each(collection, (item) => {
        if (item.hasSubItems()) {
          const found = this.findByRouteRecursive(route, item.subItems);

          if (found) {
            result = found;
            return false;
          }
        }
      });
    }

    return result;
  }

  get currentlyOpen() {
    return this._currentlyOpen;
  }

  getSidenavItemByRoute(route) {
    return this.findByRouteRecursive(route, this._items);
  }
}
