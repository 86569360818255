import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { AdminResolverService } from '../core/resolvers/admin.resolver';
import { SharedModule } from './../shared/shared.module';
import { CustomModule } from './custom/custom.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { LoginModule } from './login/login.module';
import { MasterLandingComponent } from './master-landing/master-landing.component';



@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    LoginModule,
    CustomModule,
    ForgotPasswordModule,
  ],
  exports: [
    CoreModule,
    LoginModule,
    ForgotPasswordModule,
  ],
  providers: [
    AdminResolverService,
  ],
  declarations: [
    MasterLandingComponent,
  ],
})
export class FeaturesModule { }
