import { Injectable } from '@angular/core';

import { JwtHelper } from 'angular2-jwt';
import * as sha256 from 'sha256';

import { UUID } from 'angular2-uuid';

@Injectable()
export class EncriptService {
  jwt: JwtHelper;

  constructor() {
    this.jwt = new JwtHelper();
  }

  sha(data) {
    return sha256(data);
  }

  getUuid() {
    return UUID.UUID();
  }

}
