import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as StackTraceParser from 'error-stack-parser';
import { ConfigService } from './../../services/config/config.service';
import { UserService } from './../../services/user/user.service';
import { WkHttpService } from './../../services/wk-http/wk-http.service';





@Injectable()
export class ErrorsService {

  constructor(
    private injector: Injector,
    private router: Router,
    private userService: UserService,
    private wkHttpService: WkHttpService,
  ) {}

  log(error) {
    // Log the error to the console
    console.error(error);
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    return this.report(errorToSend);
  }

  addContextInfo(error): ErrorWithContext {
    // You can include context details here (usually coming from other services: UserService...)
    const name = error.name || null;
    // TODO: Take the app's id from the Server
    const configService = this.injector.get(ConfigService);
    const appId = configService.bbdd;
    const bbdd = configService.bbdd;
    const user = this.userService.getUser().username;
    const time = new Date().getTime();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    const errorWithContext = {name, appId, user, time, id, url, status, message, stack, bbdd};

    return errorWithContext;
  }

  report(error) {
    return this.wkHttpService.post('errors', error);
  }
}
