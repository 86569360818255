import { Injectable } from '@angular/core';

import { WkHttpService } from './../wk-http/wk-http.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class WkqlService {

  

  constructor(
    private wkHttpService: WkHttpService,
  ) { }

  endpoint(endpoint, values) {
    return this.wkHttpService.get('wakaql', { params: {endpoint: endpoint, hash: environment.hash, values: values} }, environment.wkqlPath);
  }
}
