/**
 * WK-AUTH SYSTEM EXPLANATION:
 *
 * - When the app bootstraps, it tries to read the token from the SessionStorage
 *   or the LocalStorage, if it is not present, it redirects to login.
 *
 * - When the user logs in, the User information is setted in:
 *    - LocalStorage/SessionStorage:
 *      Depending on if the 'Keep me logged' is checked or not, the token
 *      is saved in SessionStorage or in LocalStorage and then it is setted
 *      in UserService.
 *
 *    - UserService:
 *      Reads from and writes to the localStorage the unencrypted user's
 *      info to be consumed from all the app.
 *
 * - The base router state for the app is 'admin', all the admin pages are childs
 *   of 'admin'.
 *
 * - 'admin' state has an canActivate and canActivateChild guard
 *   that ckecks if the user is authenticated (if there is a token in
 *   localStorage or sessionStorage) through AuthService.getToken(). If not, the
 *   user is redirected to 'login' state.
 *
 *   The user's authetication is checked in every route change inside
 *   the 'admin' state.
 *
 *  - There is an HttpInterceptor that hads the token to every http call.
 *
 **/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../services/config/config.service';
import { UserService } from '../../services/user/user.service';
import { WkHttpService } from '../../services/wk-http/wk-http.service';
import { EncriptService } from '../encript-service/encript.service';





@Injectable()
export class AuthService {
  // Save the guarded url to redirect to it when the user logs in
  redirectUrl: string;
  endPoint: string;

  constructor(
    private router: Router,
    private wkHttpService: WkHttpService,
    private encriptService: EncriptService,
    private configService: ConfigService,
    private userService: UserService,
  ) {
    this.endPoint = 'auth';
  }

  login(username: string, password: string, keepMeLoggedIn?: boolean): Observable<IAuthTokenData> {
    const params = {
        username,
        password: this.encriptService.sha(password),
    };

    return this.wkHttpService
                    .get(`${this.endPoint}/login`, { params })
                    .pipe(
                      map((token: IAuthToken) => {
                        const tokenData = this.encriptService.jwt.decodeToken(token.access_token);
                        this.setToken(`${token.token_type} ${token.access_token}`, keepMeLoggedIn);
                        this.userService.setUser(tokenData.user);
                        this.configService.setBbdd(tokenData.bbdd);

                        return tokenData;
                      })
                    );
  }

  logout() {
    this.userService.removeUser();
    this.configService.removeConfig();
    this.deleteToken();
    this.router.navigate(['login']);
  }

  getToken() {
    return localStorage.getItem('token') || sessionStorage.getItem('token');
  }

  setToken(token, keepMeLoggedIn?) {
    const storage = keepMeLoggedIn ? localStorage : sessionStorage;
    storage.setItem('token', token);
  }

  deleteToken() {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  }

  resetPassword(table: string, emailField: string, userId: string) {
    const params = {
      params: {
        table: table,
        emailField: emailField,
        userId: userId
      }
    };
    return this.wkHttpService.get(`auth/reset-password`, params);
  }

}
