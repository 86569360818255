import { forkJoin as observableForkJoin, Observable } from 'rxjs';

import { switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FilesService } from './../../../../core/services/files/files.service';
import {
  Component,
  OnInit,
  Optional,
  Host,
  Input,
  SkipSelf
} from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  FormControl,
  FormArray,
  AbstractControl,
  FormBuilder
} from '@angular/forms';

@Component({
  selector: 'wk-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss']
})
export class FileManagerComponent implements OnInit {
  parentControlContainer: ControlContainer;
  controlContainer: ControlContainer;
  filesArrayForm: FormArray;
  filesForm: FormGroup;
  files: FormControl;
  file: IFile = {
    name: '',
    type: '',
    size: '',
    path: '',
    url: '',
    title: '',
    link: '',
    target: '',
    base64: '',
    basePath: '',
    _id: '',
    order: 0,
    update_time: ''
  };
  sortablejsOptions;

  @Input()
  label: string;
  @Input()
  multiple: boolean;
  @Input()
  required: boolean;

  constructor(
    // Get a reference to the form and formArrayName. It's not working as a
    // directive in this host element :( (formArrayName extends ControlContainer)
    @Optional() @Host() @SkipSelf() parentControlContainer: ControlContainer,
    @Optional() @Host() controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private filesService: FilesService,
    private router: Router
  ) {
    this.controlContainer = controlContainer;
    this.parentControlContainer = parentControlContainer;
  }

  ngOnInit() {
    // Trick to assign the proper form and formGroup inside the formArray to this component
    this.filesForm = this.parentControlContainer.control as FormGroup;
    this.filesArrayForm = this.controlContainer.control as FormArray;
    this.files = new FormControl();
    this.sortablejsOptions = {
      onUpdate: (event: any) => {
        this.filesArrayForm.controls.forEach((control, index) => {
          this.filesArrayForm
            .at(index)
            .get('order')
            .setValue(++index);
        });
      }
    };
    this.files.valueChanges
      .pipe(
        switchMap((files: IFile[]) => {
          const fileQueries: Observable<IFile>[] = [];
          const section =
            this.router.url.split('/')[1] === 'static'
              ? this.router.url.split('/')[2]
              : this.router.url.split('/')[1];
          Array.from(files).map(file => {
            const fileToPost = {
              name: file.name,
              type: file.type,
              size: file.size,
              base64: file.base64.split(',')[1],
              basePath: this.router.url.split('/')[1],
              section: section,
              update_time: new Date().toISOString(),
              order: file.order,
              title: file.name,
              link: '',
              target: '_blank'
            };
            fileQueries.push(this.filesService.saveAndGetDetails(fileToPost));
          });

          return observableForkJoin(fileQueries);
        })
      )
      .subscribe(files => {
        this.fillArrayForm(files);
      });
  }

  fillArrayForm(files: IFile[]) {
    files.forEach(file => {
      const fileToPush = {
        name: file.name,
        type: file.type,
        size: file.size,
        url: file.url,
        _id: file._id,
        basePath: file.basePath,
        update_time: file.update_time,
        order: file.order,
        path: '',
        title: '',
        link: '',
        target: '',
        section: '',
        prop: '',
        nodeId: ''
      };
      const formGroup = this.formBuilder.group(fileToPush);
      this.filesArrayForm.push(formGroup);
    });
  }
}
